import "./chatpop.css"
import { useState, useEffect } from "react"
import user from "../../../assets/faces/1.jpg"

const ChatPopUp = () => {
  const date = new Date()
  const time = `${date.getHours()}:${date.getMinutes()}`
  const [chatPopMin, setChatPopMin] = useState(false)
  const [chatPopShow, setChatPopShow] = useState(true)
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([
    {
      type: "right",
      message: "How are you finding dreambird?",
      time,
    },
    {
      type: "left",
      message: "It’s like a dream",
      time,
    },
  ])

  const messagesHandle = (e) => {
    e.preventDefault()
    if (message.trim().length > 0) {
      setMessages((prev) => [
        ...prev,
        {
          type: "right",
          message: message.trim(),
          time,
        },
      ])
      setMessage("")
    }
  }

  useEffect(() => {
    const element = document.getElementById("chat__pop__body")
    element.scrollTop = element.scrollHeight
  }, [])

  return (
    <>
      {chatPopShow && (
        <div className={`chat__popup ${!chatPopMin && "chat__popup__hide"}`}>
          <div className="custom__card h-100">
            <ChatHeader
              chatPopMin={chatPopMin}
              setChatPopMin={setChatPopMin}
              setChatPopShow={setChatPopShow}
            />
            <ChatBody messages={messages} />
            <ChatFooter
              message={message}
              setMessage={setMessage}
              messagesHandle={messagesHandle}
            />
          </div>
        </div>
      )}
    </>
  )
}

const ChatHeader = ({ chatPopMin, setChatPopMin, setChatPopShow }) => {
  return (
    <div className="chat__header">
      <div className="d-flex align-items-center">
        <div className="chat__header__img">
          <img
            src={user}
            alt=""
          />
        </div>
        <div className="chat__header__info">
          <div>Jesse Tubalt</div>
        </div>
      </div>
      <div className="chat__pop__header__icons">
        <div
          className="icon__wrapper"
          onClick={() => setChatPopMin((prev) => !prev)}
        >
          {!chatPopMin ? (
            <i className="bi bi-square"></i>
          ) : (
            <i className="bi bi-dash-lg"></i>
          )}
        </div>
        <div
          className="icon__wrapper"
          onClick={() => setChatPopShow((prev) => !prev)}
        >
          <i className="bi bi-x-lg"></i>
        </div>
      </div>
    </div>
  )
}

const ChatBody = ({ messages }) => {
  return (
    <div
      className="chat__body"
      id="chat__pop__body"
    >
      <div className="chat__body__content">
        {messages.map((message) => (
          <div className={`chat__body__${message.type} chat__content__child`}>
            <p>{message.message}</p>
            <span>Today {message.time}</span>
          </div>
        ))}
        {/* <div className="chat__body__left chat__content__child">
                    <a href="https://dreamschat.dreamguystech.com/template/assets/img/media2.jpg" download>
                        <img src="https://dreamschat.dreamguystech.com/template/assets/img/media2.jpg" alt="" />
                    </a>
                    <span>Today 14:40 PM</span>
                </div> */}
      </div>
    </div>
  )
}

const ChatFooter = ({ message, setMessage, messagesHandle }) => {
  return (
    <div className="chat__footer">
      <form
        className="d-flex align-items-center"
        onSubmit={messagesHandle}
      >
        <input
          type="text"
          placeholder="Write a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <div className="chat__emoji">
          <i className="fa fa-smile"></i>
        </div>
        <div>
          <label htmlFor="chat__file">
            <i className="fa fa-paperclip"></i>
          </label>
          <input
            type="file"
            id="chat__file"
          />
        </div>
        <button type="submit">
          <i className="fa-brands fa-telegram"></i>
        </button>
      </form>
    </div>
  )
}

export default ChatPopUp
