import "./content.css"
import { Link } from "react-router-dom"
import Carousel from "react-multi-carousel"
import content1 from "../../../../assets/content1.png"
import content2 from "../../../../assets/content2.png"
import content3 from "../../../../assets/content3.png"
import content4 from "../../../../assets/content4.png"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1499, min: 1200 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1199, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const Content = () => {
  return (
    <div className="dashboard__content__library">
      <div className="custom__card">
        <div className="custom__card__header">
          <p>Things To Watch</p>
          <Link to={"/content-management"}>View Full Content Library</Link>
        </div>
        <div className="content__slider__cont">
          <Carousel
            responsive={responsive}
            draggable={true}
          >
            <div className="content__slider__item">
              <img
                src={content1}
                alt=""
              />
            </div>
            <div className="content__slider__item">
              <img
                src={content2}
                alt=""
              />
            </div>
            <div className="content__slider__item">
              <img
                src={content3}
                alt=""
              />
            </div>
            <div className="content__slider__item">
              <img
                src={content4}
                alt=""
              />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Content
