import Ads from './components/ads'
import Film from './components/film'
import Payouts from './components/payouts'
import Work from './components/work'
import './graphs.css'

const Graphs = () => {
    return (
        <>
            <Film />
            <br />
            <Ads />
            <br />
            <div className='graph__cont2'>
                <Work />
                <Payouts />
            </div>
        </>
    )
}

export default Graphs