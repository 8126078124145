import "./upload.css"
import upload from "../../../../assets/quick_upload.svg"

const Upload = () => {
  return (
    <div className="quick__upload__cont">
      <div className="custom__card">
        <div className="custom__card__header">Quick Upload</div>
        <div className="d-flex">
          <div>
            <p className="quick__upload__subtitle">
              Ready to upload your new content?
            </p>
            <div className="text-center">
              <label
                // htmlFor="quick__upload"
                className="quick__upload__btn"
              >
                <p>
                  <i className="fa fa-add"></i>
                </p>
              </label>
              {/* <input
                type="file"
                id="quick__upload"
              /> */}
              <p style={{ fontSize: "12px" }}>Upload Now</p>
            </div>
          </div>
          <div >
            <div className="quick__upload__pic">
              <img
                src={upload}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Upload
