import "./marketplace.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import Filters from "../dashboard_panel/filters/filters"
import unseen from "../../../assets/marketplace/unseen.jpg"
import vanishing from "../../../assets/marketplace/vanishing.jpg"
import junior from "../../../assets/marketplace/junior.jpg"
import summit from "../../../assets/marketplace/summit.jpg"
import sg50 from "../../../assets/marketplace/50sg.jpg"
import _300 from "../../../assets/marketplace/300.jpg"
import arrival from "../../../assets/marketplace/arrival.jpg"
import borat from "../../../assets/marketplace/borat.jpg"
import boyhood from "../../../assets/marketplace/boyhood.jpg"
import bswan from "../../../assets/marketplace/bswan.jpg"
import bttf from "../../../assets/marketplace/bttf.jpg"
import cthd from "../../../assets/marketplace/cthd.jpg"
import dd from "../../../assets/marketplace/dd.jpg"
import exmachina from "../../../assets/marketplace/exmachina.jpg"
import gravity from "../../../assets/marketplace/gravity.jpg"
import hg from "../../../assets/marketplace/hg.jpg"
import fg from "../../../assets/marketplace/fg.jpg"
import freeguy from "../../../assets/marketplace/freeguy.jpg"
import go from "../../../assets/marketplace/go.jpg"
import gbh from "../../../assets/marketplace/gbh.jpg"
import image1 from "../../../assets/community/1.jpg"
import image2 from "../../../assets/community/2.jpg"
import image3 from "../../../assets/community/3.jpg"
import image4 from "../../../assets/community/4.jpg"
import image5 from "../../../assets/community/5.jpg"
import image6 from "../../../assets/community/6.jpg"
import image7 from "../../../assets/community/7.jpg"
import image8 from "../../../assets/community/8.jpg"
import image9 from "../../../assets/community/9.jpg"
import { useNavigate } from "react-router-dom"

const channels = [
  {
    title: "Drama",
    image: image8,
  },
  {
    title: "Science Fiction",
    image: image9,
  },
  {
    title: "Documentary",
    image: image5,
  },
  {
    title: "Horror",
    image: image4,
  },
  {
    title: "Thriller",
    image: image1,
  },
  {
    title: "Comedy",
    image: image3,
  },
  {
    title: "Romance ",
    image: image2,
  },
  {
    title: "For Kids",
    image: image6,
  },
  {
    title: "Action",
    image: image7,
  },
]

const projects = [
  {
    title: "The Unseen",
    image: unseen,
    description:
      "A supernatural teen drama series about a group of high school students who have the ability to see and communicate with ghosts and use their powers to help restless spirits cross over and solve the mysteries surrounding their deaths while facing powerful and dangerous forces.",
  },
  {
    title: "The Vanishing Forests",
    image: vanishing,
    description:
      "A documentary that explores the devastating effects of deforestation on the world's forests and wildlife, highlights successful conservation efforts, and serves as a call to action for viewers to become stewards of the planet and work towards a more sustainable future.",
  },
  {
    title: "Junior Chef ",
    image: junior,
    description:
      "A children's cooking show that showcases young talent while teaching young viewers how to cook healthy and delicious meals. Full of fun, culture and cuisines.",
  },
  {
    title: "Summit Seekers",
    image: summit,
    description:
      "A movie about a group of amateur climbers who embark on an epic journey to summit one of the world's most challenging peaks and face numerous obstacles and challenges along the way, testing their friendship, perseverance, and inner strength.",
  },
  {
    title: "Fifty Shades of Grey",
    image: sg50,
    description:
      "Literature student Anastasia Steele's life changes forever when she meets handsome, yet tormented, billionaire Christian Grey.",
  },
  {
    title: "300",
    image: _300,
    description:
      "King Leonidas of Sparta and a force of 300 men fight the Persians at Thermopylae in 480 B.C.",
  },
  {
    title: "Arrival",
    image: arrival,
    description:
      "A linguist works with the military to communicate with alien lifeforms after twelve mysterious spacecrafts appear around the world.",
  },
  {
    title: "Borat",
    image: borat,
    description:
      "Kazakh TV talking head Borat is dispatched to the United States to report on the greatest country in the world. With a documentary crew in tow, Borat becomes more interested in locating and marrying Pamela Anderson.",
  },
  {
    title: "Boyhood",
    image: boyhood,
    description:
      "The life of Mason, from early childhood to his arrival at college.",
  },
  {
    title: "Black Sawan",
    image: bswan,
    description:
      "A committed dancer struggles to maintain her sanity after winning the lead role in a production of Tchaikovsky's 'Swan Lake'.",
  },
]

const recommendations = [
  {
    title: "Crouching Tiger Hidden Dragon",
    image: cthd,
    description:
      "A young Chinese warrior steals a sword from a famed swordsman and then escapes into a world of romantic adventure with a mysterious man in the frontier of the nation.",
  },
  {
    title: "Ex-Machina",
    image: exmachina,
    description:
      "A young programmer is selected to participate in a ground-breaking experiment in synthetic intelligence by evaluating the human qualities of a highly advanced humanoid A.I.",
  },
  {
    title: "Dumb and Dumber",
    image: dd,
    description:
      "After a woman leaves a briefcase at the airport terminal, a dumb limo driver and his dumber friend set out on a hilarious cross-country road trip to Aspen to return it.",
  },
  {
    title: "Gravity",
    image: gravity,
    description:
      "Two astronauts work together to survive after an accident leaves them stranded in space.",
  },
  {
    title: "Happy Gilmore",
    image: hg,
    description:
      "A rejected hockey player puts his skills to the golf course to save his grandmother's house.",
  },
  {
    title: "Forest Gump",
    image: fg,
    description:
      "The presidencies of Kennedy and Johnson, the Vietnam War, the Watergate scandal and other historical events unfold from the perspective of an Alabama man with an IQ of 75, whose only desire is to be reunited with his childhood sweetheart.",
  },
  {
    title: "Back To The Future",
    image: bttf,
    description:
      "Marty McFly, a 17-year-old high school student, is accidentally sent 30 years into the past in a time-traveling DeLorean invented by his close friend, the maverick scientist Doc Brown",
  },
  {
    title: "Free Guy",
    image: freeguy,
    description:
      "A bank teller discovers that he's actually an NPC inside a brutal, open world video game.",
  },
  {
    title: "Get Out",
    image: go,
    description:
      "A young African-American visits his white girlfriend's parents for the weekend, where his simmering uneasiness about their reception of him eventually reaches a boiling point.",
  },
  {
    title: "Grand Budapest Hotel",
    image: gbh,
    description:
      "A writer encounters the owner of an aging high-class hotel, who tells him of his early years serving as a lobby boy in the hotel's glorious years under an exceptional concierge.",
  },
]

const MarketplaceSection = () => {
  return (
    <div className="marketplace__cont">
      <Filters />
      <div style={{ margin: "1rem 0" }}></div>
      <div className="marketplace__slider">
        <div className="custom__card">
          <div className="custom__card__header">Projects</div>
          <div className="marketplace__slider__cont">
            <Swiper
              modules={[Navigation]}
              slidesPerView={"auto"}
              spaceBetween={15}
              navigation={true}
            >
              {projects.map((item, index) => (
                <SwiperSlide>
                  <Card
                    title={item.title}
                    image={item.image}
                    description={item.description}
                    index={index}
                    link={true}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="marketplace__slider_sep"></div>
      <div className="marketplace__slider">
        <div className="custom__card">
          <div className="custom__card__header">Channels</div>
          <div className="marketplace__slider__cont">
            <Swiper
              modules={[Navigation]}
              slidesPerView={"auto"}
              spaceBetween={15}
              navigation={true}
            >
              {channels.map((item, index) => (
                <SwiperSlide>
                  <Card
                    title={item.title}
                    image={item.image}
                    description={item.description}
                    index={index}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="marketplace__slider_sep"></div>
      <div className="marketplace__slider">
        <div className="custom__card">
          <div className="custom__card__header">Recommendations</div>
          <div className="marketplace__slider__cont">
            <Swiper
              modules={[Navigation]}
              slidesPerView={"auto"}
              spaceBetween={15}
              navigation={true}
            >
              {recommendations.map((item, index) => (
                <SwiperSlide>
                  <Card
                    index={index}
                    title={item.title}
                    image={item.image}
                    description={item.description}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

const Card = ({ index, title, image, description, link }) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        if (!link) return
        navigate.push("/jobs?type=project")
      }}
      className="marketplace__card custom__card"
      key={index}
    >
      <div className="marketplace__card__img">
        <img
          decoding="async"
          loading="lazy"
          style={{ cursor: "pointer" }}
          src={image}
          alt=""
        />
      </div>
      <div className="marketplace__card__body">
        <p className="marketplace__card__title">{title}</p>
        <p className="marketplace__card__desc">{description}</p>
      </div>
    </div>
  )
}

export default MarketplaceSection
