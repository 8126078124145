import './friends.css'
import SearchFriends from './components/search'
import AllFriends from './components/all'

import user1 from '../../../assets/faces/1.jpg'
import user2 from '../../../assets/faces/2.jpg'
import user3 from '../../../assets/faces/3.jpg'
import user4 from '../../../assets/faces/4.jpg'
import user5 from '../../../assets/faces/5.jpg'
import user6 from '../../../assets/faces/6.jpg'
import user7 from '../../../assets/faces/7.jpg'
import user8 from '../../../assets/faces/8.jpg'
import user9 from '../../../assets/faces/9.jpg'
import user10 from '../../../assets/faces/10.jpg'

const users = [
    {
        name: "Jesse Tubalt",
        image: user1
    },
    {
        name: "Kerry Ingleton",
        image: user2
    },
    {
        name: "Sarah Keale",
        image: user3
    },
    {
        name: "Lance Potter",
        image: user4
    },
    {
        name: "Edi Kamau",
        image: user5
    },
    {
        name: "Toby McIntyre",
        image: user6
    },
    {
        name: "Hannah Field",
        image: user7
    },
    {
        name: "Jacki Backster",
        image: user8
    },
    {
        name: "Robert Trindiel",
        image: user9
    },
    {
        name: "Heidi Hernandez",
        image: user10
    },
]

const Friends = () => {
    return (
        <div className="dashboard__cont friends__cont">
            <SearchFriends users={users} />
            <AllFriends users={users} />
        </div>
    )
}

export default Friends