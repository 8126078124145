import { useEffect } from "react";
import ForgotPasswordSection from "../components/dashboard/forgot_section/forgot";

function ForgotPassword() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ForgotPasswordSection />
        </>
    )
}

export default ForgotPassword