import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import FriendsSection from "../components/dashboard/friends/friends";
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

const Friends = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <div className="dashboard__panel">
                <Header />
                <div className='dashboard'>
                    <FriendsSection />
                </div>
            </div>
        </>
    )
}

export default Friends