import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import ContentManagmentFilesPanel from "../components/dashboard/content_managment/content_managment_files";
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

function ContentManagmentFiles() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <Header />
            <ContentManagmentFilesPanel />
        </>
    )
}

export default ContentManagmentFiles