import "./community.css"
import { Link } from "react-router-dom"
import Carousel from "react-multi-carousel"
import profile from "../../../../assets/profile.svg"
import video_community from "../../../../assets/video_community.svg"
import image_community from "../../../../assets/image_community.svg"
import logo from "../../../../assets/logo2.svg"
import feed1 from "../../../../assets/feed.png"
import feed2 from "../../../../assets/feed2.png"
import like from "../../../../assets/like.svg"
import comment from "../../../../assets/comment.svg"
import share from "../../../../assets/share.svg"
import { useRef } from "react"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Community = () => {
  const scrollRef = useRef()
  const scrollHandle = () => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight
  }
  return (
    <div className="dashboard__community__wrapper">
      <div className="custom__card">
        <div className="custom__card__header">Community</div>
        <CreatePost />
        <Banner />
        <div className="dashboard__community__sep"></div>
        <div className="dashboard__community__feed__wrapper">
          <div className="feed__header mb-2">
            <p>Following</p>
            <Link to="/community-forum">View Full Feed</Link>
          </div>
          <div
            className="feed__cont"
            ref={scrollRef}
          >
            <Feed image={feed1} />
            <br />
            <Feed image={feed2} />
            <div
              className="scroll__down"
              onClick={scrollHandle}
            >
              <i className="bi bi-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CreatePost = () => {
  return (
    <>
      <div className="community__create__wrapper mb-3">
        <div className="community__user__img">
          <img
            src={profile}
            alt=""
          />
        </div>
        <div className="community__input">
          <input
            type="text"
            placeholder="Something new to share?"
          />
          <label htmlFor="video__upload">
            <img
              src={video_community}
              alt=""
            />
            <input
              type="file"
              id="video__upload"
            />
          </label>
          <label htmlFor="image__upload">
            <img
              src={image_community}
              alt=""
            />
            <input
              type="file"
              id="image__upload"
            />
          </label>
        </div>
      </div>
    </>
  )
}

const Banner = () => {
  return (
    <div className="community__banner__wrapper">
      <Carousel
        responsive={responsive}
        draggable={false}
        showDots={false}
        infinite={false}
        autoPlay={false}
        arrows={false}
        autoPlaySpeed={2500}
      >
        {Array(1)
          .fill()
          .map((i, index) => (
            <div className="community__banner">
              <h3>DB Happy Hour</h3>
              <div className="d-flex align-items-center">
                <div className="banner__img__wrapper">
                  <img
                    src={logo}
                    width={25}
                    alt=""
                  />
                </div>
                <p>
                  At 160{index} today, earn more tokens during our Happy Hour
                  Blitz.
                </p>
              </div>
              <div className="text-end my-2">
                <button>HOST</button>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  )
}

const Feed = ({ image }) => {
  return (
    <div className="feed__card">
      <img
        src={image}
        alt=""
      />
      <div className="feed__content">
        <div className="feed__title">Poseidon Studios</div>
        <p className="feed__desc">
          Today, I’m so stoked!!! We started on our Rom-Com Sci-Fi short. Check
          out our page for live BTS streams
        </p>
        <div className="feed__content__sep"></div>
        <div className="feed__action">
          <img
            src={like}
            alt=""
          />
          <img
            src={comment}
            alt=""
          />
          <img
            src={share}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Community
