import { useEffect } from 'react'
import Hero from '../components/website/form/form'
import Footer from '../components/website/footer/footer'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="homepage contact_us">
            <Hero />
            <Footer />
        </div>
    )
}

export default Contact