import "./forum.css"
import { useState } from "react"
import ProgressBar from "@ramonak/react-progress-bar"
import profile from "../../../assets/profile.svg"
import community_image from "../../../assets/logo2.svg"
import video_community_file from "../../../assets/video_community.svg"
import image_community_file from "../../../assets/image_community.svg"
import feed from "../../../assets/feed.png"
import like from "../../../assets/like.svg"
import comment from "../../../assets/comment.svg"
import share from "../../../assets/share.svg"
import mission from "../../../assets/mission.svg"
import user1 from "../../../assets/faces/1.jpg"
import user2 from "../../../assets/faces/2.jpg"
import user3 from "../../../assets/faces/3.jpg"
import poseidon from "../../../assets/poseidon.png"
import irregular from "../../../assets/irregular.jpg"

const users = [
  {
    name: "Jesse Tubalt",
    image: user1,
  },
  {
    name: "Kerry Ingleton",
    image: user2,
  },
  {
    name: "Sarah Keale",
    image: user3,
  },
]

const ForumSection = () => {
  return (
    <>
      <div className="dashboard__child1">
        <div>
          <CreatePost />
          <Feed
            feed={null}
            logo={community_image}
            title="Dreambird"
            desc="Are you a professional in the movie industry? Are you a budding filmmaker that’s struggling to break into the industry? Are you a film fan, having a hard time finding movies you really love? As a fan, what would you like from a film platform? We at Dreambird would love to hear your thoughts, your ideas, your struggles, your needs. Together, we can build the future of film. Feel free to share your projects so that we can support one another to achieve our dreams."
            comments={false}
          />
          <Feed
            feed={feed}
            logo={poseidon}
            title="Poseidon Studios"
            desc="Today, I’m so stoked!!! We started on our Rom-Com Sci-Fi short.
              Check out our page for live BTS streams"
            comments={true}
          />
          <Feed
            feed={null}
            logo={irregular}
            title="Irregular Features"
            desc={`We have just created our first film project on Dreambird, check it out now and come make it with us! It’s a fantasy action like you’ve never seen before!\n\n\n`}
            comments={false}
          />
        </div>
      </div>
      <div className="dashboard__child2 community__about">
        <div>
          <About />
          <Mission />
        </div>
      </div>
    </>
  )
}

const CreatePost = () => {
  return (
    <div className="custom__card mb-4">
      <div className="community__create__wrapper">
        <div className="community__user__img">
          <img
            src={profile}
            alt=""
          />
        </div>
        <div className="community__input">
          <input
            type="text"
            placeholder="Something new to share?"
          />
          <label htmlFor="video__upload">
            <img
              src={video_community_file}
              alt=""
            />
            <input
              type="file"
              id="video__upload"
            />
          </label>
          <label htmlFor="image__upload">
            <img
              src={image_community_file}
              alt=""
            />
            <input
              type="file"
              id="image__upload"
            />
          </label>
        </div>
      </div>
    </div>
  )
}

const Feed = ({ feed, logo, title, desc, comments }) => {
  const [toggle, setToggle] = useState(true)
  const commentToggle = () => setToggle((prev) => !prev)
  return (
    <div className="dashboard__community__feed__wrapper mb-4">
      <div className="feed__cont custom__card">
        <div className="feed__card">
          {feed && (
            <img
              src={feed}
              alt=""
              className="feed___img"
            />
          )}
          <div className="feed__content">
            <div className="feed__title mb-2 mt-1">
              <img
                src={logo}
                alt=""
              />
              <p>Posted by 2 days ago</p>
            </div>
            <div className="feed__title">{title}</div>
            <p className="feed__desc">{desc}</p>
            <div className="feed__content__sep"></div>
            <div className="feed__action">
              <img
                src={like}
                alt=""
              />
              <img
                onClick={commentToggle}
                src={comment}
                alt=""
              />
              <img
                src={share}
                alt=""
              />
            </div>
          </div>
        </div>
        {toggle && (
          <>
            <div className="feed__content__sep"></div>
            {comments && (
              <>
                <div
                  className="mb-3"
                  style={{ fontWeight: "600" }}
                >
                  All Comments
                </div>
                <Comments />
              </>
            )}
            <div
              className="community__create__wrapper"
              style={{ padding: "5px 8px" }}
            >
              <div className="community__user__img">
                <img
                  src={profile}
                  alt=""
                />
              </div>
              <div className="community__input">
                <input
                  type="text"
                  placeholder="Write comment..."
                />
                <label htmlFor="video__upload">
                  <img
                    src={video_community_file}
                    alt=""
                  />
                  <input
                    type="file"
                    id="video__upload"
                  />
                </label>
                <label htmlFor="image__upload">
                  <img
                    src={image_community_file}
                    alt=""
                  />
                  <input
                    type="file"
                    id="image__upload"
                  />
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const Comments = () => {
  return (
    <>
      <div className="community__feed__comments">
        <div className="comments__body">
          <div className="comments__parent">
            <img
              src={users[1]?.image}
              alt=""
            />
            <div className="comments__wrapper">
              <div className="comment">
                <div>{users[1]?.name}</div>
                <span>2 hours ago</span>
              </div>
              <div className="comment">
                <p>
                  I am so excited, I’ve been waiting for this and it’s finally
                  happening
                </p>
              </div>
              <div className="comment__action">
                <p>Like</p> &nbsp; &nbsp; &nbsp;
                <p>Reply</p>
              </div>
            </div>
          </div>
          <div className="comment__reply">
            <div className="comments__parent">
              <img
                src={users[2]?.image}
                alt=""
              />
              <div className="comments__wrapper">
                <div className="comment">
                  <div>{users[2]?.name}</div>
                  <span>2 hours ago</span>
                </div>
                <div className="comment">
                  <p>
                    The live streams have been so insightful, I didn’t know you
                    can fake a spaceship like that!
                  </p>
                </div>
                <div className="comment__action">
                  <p>Like</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const About = () => {
  return (
    <div className="custom__card mb-3">
      <div className="custom__card__header mb-1">
        <h6 className="fw-bold">About Community</h6>
      </div>
      <div className="community__about__info">
        <img
          src={community_image}
          alt=""
        />
        <p>Dreambird</p>
      </div>
      <div className="community__about__content">
        Welcome to the first community made for you! The filmmakers, film pros,
        film fans, prosumers, fansumers, and anyone else who is interested in
        being part of the new film world. Now is the time to reimagine the film
        industry together!{" "}
      </div>
      <div className="community__about__created">Created Nov 25, 2022</div>
      <div className="feed__content__sep"></div>
      <div className="community__about__stats">
        <div>
          <div>38.8m</div> <p>Members</p>
        </div>
        <div>
          <div>78.3k</div> <p>Online</p>
        </div>
        <div>
          <div>#12</div> <p>ranked by Size</p>
        </div>
      </div>
    </div>
  )
}

const Mission = () => {
  return (
    <div className="mission__wrapper">
      <div className="custom__card">
        <div className="custom__card__header">Achievements</div>
        <div className="mission__body">
          <div className="icon__wrapper">
            <img
              src={mission}
              alt=""
            />
          </div>
          <div
            className="d-flex justify-content-between flex-column w-100"
            style={{ marginLeft: "8px" }}
          >
            <div className="mission__title">
              <p>Have 100 films available to watch</p>
              <br />
            </div>
            <div className="mission__progress">
              <ProgressBar
                completed={10}
                maxCompleted={10}
                bgColor={"#FFB238"}
                height={6}
                isLabelVisible={false}
                animateOnRender={true}
              />
            </div>
          </div>
        </div>
        <div className="feed__content__sep"></div>
        <div className="mission__body">
          <div className="icon__wrapper">
            <img
              src={mission}
              alt=""
            />
          </div>
          <div
            className="d-flex justify-content-between flex-column w-100"
            style={{ marginLeft: "8px" }}
          >
            <div className="mission__title">
              <p>Reached 1000 posts</p>
              <br />
            </div>
            <div className="mission__progress">
              <ProgressBar
                completed={7}
                maxCompleted={7}
                bgColor={"#FFB238"}
                height={6}
                isLabelVisible={false}
                animateOnRender={true}
              />
            </div>
          </div>
        </div>
        <div className="feed__content__sep"></div>
        <div className="mission__body">
          <div className="icon__wrapper">
            <img
              src={mission}
              alt=""
            />
          </div>
          <div
            className="d-flex justify-content-between flex-column w-100"
            style={{ marginLeft: "8px" }}
          >
            <div className="mission__title">
              <p>Created a community made movie</p>
              <br />
            </div>
            <div className="mission__progress">
              <ProgressBar
                completed={7}
                maxCompleted={7}
                bgColor={"#FFB238"}
                height={6}
                isLabelVisible={false}
                animateOnRender={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForumSection
