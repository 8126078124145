import "./filters.css"
import search from "../../../../assets/search.svg"
import { editIcon, filterIcon, sortIcon } from "../../../../utils/icons"

const Filters = () => {
  return (
    <div className="dashboard__filters">
      <div className="input__filter">
        <img
          src={search}
          alt=""
        />
        <input
          type="text"
          placeholder="Search names, titles, events, etc."
        />
        <div className="filter_wrapper">
          <span>Advanced Filters</span>
          <p>{filterIcon}</p>
        </div>
      </div>
      <div className="filter__spacer"></div>
      <div className="sort__filter">
        <div className="filter_wrapper">
          <span>Sort</span>
          <p>{sortIcon}</p>
        </div>
        <div className="filter_wrapper mx-3">
          <span>Customize</span>
          <p>{editIcon}</p>
        </div>
      </div>
    </div>
  )
}

export default Filters
