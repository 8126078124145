import "./intro.css"
import { useState } from "react"
import "react-lazy-load-image-component/src/effects/blur.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import intro from "../../../assets/website/intro.png"
import intro_low from "../../../assets/website/intro_low.png"
import film_maker from "../../../assets/website/film_maker.jpg"
import film_maker_low from "../../../assets/website/film_maker_low.jpg"
import film_fan from "../../../assets/website/film_fan.jpg"
import film_fan_low from "../../../assets/website/film_fan_low.jpg"

const Intro = () => {
  return (
    <div className="home_intro">
      <div className="row w-100 m-auto">
        <div className="col-12 col-xl-6 intro_image">
          <LazyLoadImage
            src={intro}
            placeholderSrc={intro_low}
            effect="blur"
            height="100%"
            width="100%"
            id="intro_image"
            alt="intro"
          />
        </div>
        <div className="col-12 col-xl-6">
          <Card
            title="An Introduction to Dreamby"
            text="The internet and other digital technologies have revolutionized how films are made and distributed. However, the industry business model remains stuck in the 20th Century: a handful of companies control what films get made and seen. They then take the lion’s share of the profit.
            Now is the time to change all of that. We are working on a new platform that demolishes the walls around the film-making industry and brings film-makers and their fan communities together.
                        The result will be a whole new ecosystem for production, funding and distribution that allows fans, investors and creatives to connect, interact and stream.
                        In return film-makers will be more fairly rewarded, getting a majority of the profit back. Plus, the people that backed them will be rewarded for their support and belief.
                        Power will be handed back to the creative talent and production teams, revitalizing the independent film sector and inspiring a new generation of talent (along with the existing film-making community)."
          />
        </div>
      </div>
      <div className="row w-100 m-auto">
        <div className="col-12 col-xl-6 intro_image">
          <LazyLoadImage
            src={film_maker}
            placeholderSrc={film_maker_low}
            width="100%"
            height="100%"
            effect="blur"
            id="intro_image"
            alt="film_maker"
          />
        </div>
        <div className="col-12 col-xl-6">
          <Card
            title="For the film-maker"
            text={`The film industry is one of the most closed-off and exclusive in the navigate of human culture.\n For 110 years a small group of studios have been deciding what films get made and who gets to see them. We are changing that with an online system that brings audiences and producers together to co-create and watch films together.
            \n Dreamby will bring fairness and democracy to the industry, providing openness and transparency across funding, development, production, distribution and streaming.
            \n We will also make content more immersive by incorporating new technologies that have never been deployed before in film. Whole new genres and formats are likely to be discovered and shared. But that’s not up to us – we just provide the platform…`}
          />
        </div>
      </div>
      <div className="row w-100 m-auto">
        <div className="col-12 col-xl-6 intro_image">
          <LazyLoadImage
            src={film_fan}
            placeholderSrc={film_fan_low}
            effect="blur"
            height="100%"
            width="100%"
            id="intro_image"
            alt="film_fan"
          />
        </div>
        <div className="col-12 col-xl-6">
          <Card
            title="For the film fan"
            text="With Dreamby you get to decide not just what to watch that evening, but what gets made in the first place.
                        Support your favorite film-makers, discover new talent and engage with the concepts and teams that appeal to you.
                        Dreamby will facilitate a new structure for production, in which viewers and creators can come together to fund, develop, produce and share new films for the world to enjoy.
                        New entrants to the industry will be able to find supporting skills and a community to inspire and support them.  Smart contracts and blockchains will keep track of investments and revenue. Members will be able to earn from their activity using the Dreamby token on the site.
                        Are you a video creator? We will reward you much more than the existing platforms.
                        You will also get paid for contributing to other projects and for sharing links.
                        Do you watch ads? We will even pay you to do that.
                        You can also decide if you wish to sell your data or you can keep it private. If you do sell your data, you will get paid for that too.
                        Dreamby aims to become your fair space for social activity, visual entertainment, creation and more.
                        "
          />
        </div>
      </div>
    </div>
  )
}

const Card = ({ title, text }) => {
  const [readMore, setReadMore] = useState(false)
  return (
    <div className="intro_card">
      <div className="card_title">
        {title}
        <span>.</span>
      </div>
      {!readMore ? (
        <p>
          {text?.slice(0, 500)}{" "}
          <a
            style={{ color: "rgb(160 123 221)" }}
            onClick={() => setReadMore(true)}
          >
            <strong>Read more...</strong>
          </a>
        </p>
      ) : (
        <p>{text}</p>
      )}
    </div>
  )
}

export default Intro
