import "./community.css"
import { useNavigate } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import search from "../../../assets/search.svg"
import image1 from "../../../assets/community/1.jpg"
import image2 from "../../../assets/community/2.jpg"
import image3 from "../../../assets/community/3.jpg"
import image4 from "../../../assets/community/4.jpg"
import image5 from "../../../assets/community/5.jpg"
import image6 from "../../../assets/community/6.jpg"
import image7 from "../../../assets/community/7.jpg"
import image8 from "../../../assets/community/8.jpg"
import image9 from "../../../assets/community/9.jpg"
import image11 from "../../../assets/community/11.jpg"
import image12 from "../../../assets/community/12.jpg"
import image13 from "../../../assets/community/13.jpg"
import image14 from "../../../assets/community/14.jpg"
import image15 from "../../../assets/community/15.jpg"
import image16 from "../../../assets/community/16.jpg"
import image17 from "../../../assets/community/17.jpg"
import image18 from "../../../assets/community/18.jpg"
import image19 from "../../../assets/community/19.jpg"
import image20 from "../../../assets/community/20.jpg"
import image21 from "../../../assets/community/21.jpg"
import image22 from "../../../assets/community/22.jpg"
import image23 from "../../../assets/community/23.jpg"
import image24 from "../../../assets/community/24.jpg"
import image25 from "../../../assets/community/25.jpg"
import image26 from "../../../assets/community/26.jpg"
import image27 from "../../../assets/community/27.jpg"
import image28 from "../../../assets/community/28.jpg"
import image29 from "../../../assets/community/29.jpg"
import image31 from "../../../assets/community/31.jpg"
import image33 from "../../../assets/community/33.jpg"
import image34 from "../../../assets/community/34.jpg"
import image35 from "../../../assets/community/35.jpg"
import image36 from "../../../assets/community/36.jpg"
import image37 from "../../../assets/community/37.jpg"
import image38 from "../../../assets/community/38.jpg"

const topCommunity = [
  {
    name: "Drama",
    image: image8,
  },
  {
    name: "Science Fiction",
    image: image9,
  },
  {
    name: "Documentary",
    image: image5,
  },
  {
    name: "Horror",
    image: image4,
  },
  {
    name: "Thriller",
    image: image1,
  },
  {
    name: "Comedy",
    image: image3,
  },
  {
    name: "Romance ",
    image: image2,
  },
  {
    name: "For Kids",
    image: image6,
  },
  {
    name: "Action",
    image: image7,
  },
]

const yourCommunity = [
  {
    name: "Sports",
    image: image12,
  },
  {
    name: "Action",
    image: image7,
  },
  {
    name: "Adventure",
    image: image13,
  },
  {
    name: "Drama",
    image: image8,
  },
  {
    name: "Cooking",
    image: image14,
  },
  {
    name: "Musicals",
    image: image15,
  },
  {
    name: "Mystery",
    image: image16,
  },
  {
    name: "Science Fiction",
    image: image9,
  },
  {
    name: "Comedy",
    image: image3,
  },
  {
    name: "Crime Fiction",
    image: image11,
  },
]

const communities = [
  {
    title: "True Stories",
    image: image17,
  },
  {
    title: "Legal Dramas",
    image: image18,
  },
  {
    title: "True Crime",
    image: image19,
  },
  {
    title: "Westerns",
    image: image20,
  },
  {
    title: "Animation",
    image: image21,
  },
  {
    title: "Animé",
    image: image22,
  },
  {
    title: "Fantasy",
    image: image23,
  },
  {
    title: "Historical",
    image: image24,
  },
  {
    title: "Superhero",
    image: image25,
  },
  {
    title: "Satire",
    image: image26,
  },
  {
    title: "Cyberpunk",
    image: image27,
  },
]

const suggestions = [
  {
    title: "Experimental",
    image: image28,
  },
  {
    title: "Romantic Comedy",
    image: image29,
  },
  {
    title: "Mockumentary ",
    image: image33,
  },
  {
    title: "Silent",
    image: image34,
  },
  {
    title: "Mythology",
    image: image35,
  },
  {
    title: "Unscripted",
    image: image31,
  },
  {
    title: "Political Drama",
    image: image36,
  },
  {
    title: "Video Games",
    image: image38,
  },
  {
    title: "Talk Show",
    image: image37,
  },
  {
    title: "Superhero",
    image: image25,
  },
]

const CommunitySection = () => {
  const navigate = useNavigate()
  return (
    <div className="dashboard">
      <div className="dashboard__cont community__cont">
        <Sidebar communities={communities} />
        <div className="dashboard__child1">
          <div className="top__community">
            <div className="custom__card">
              <div className="custom__card__header">
                <h6>Top Communities</h6>
              </div>
              <div className="marketplace__slider__cont">
                <div className="top__community__card__body">
                  {topCommunity.map((item, index) => (
                    <div className="top__community__item_cont">
                      <div className="top__community__item">
                        <div
                          className="top__community__rank"
                          style={{ width: "50px" }}
                        >
                          <p>#{index + 1}</p>
                        </div>
                        <div className="community__side__card">
                          <img
                            loading="lazy"
                            decoding="async"
                            src={item.image}
                            alt=""
                          />
                          <div className="community__side__card__content">
                            <p>{item.name}</p>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate.push("/community-forum")}
                        className="loginBtn"
                      >
                        Join
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="marketplace__slider_sep"></div>
          <div className="marketplace__slider">
            <div className="custom__card">
              <div className="custom__card__header">
                <h6>Your Communities</h6>
              </div>
              <div className="marketplace__slider__cont">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={"auto"}
                  spaceBetween={15}
                  navigation={true}
                >
                  {yourCommunity.map((item, index) => (
                    <SwiperSlide>
                      <Card
                        title={item.title}
                        image={item.image}
                        index={index}
                        btnTitle="View"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
          <div className="marketplace__slider_sep"></div>
          <div className="marketplace__slider">
            <div className="custom__card">
              <div className="custom__card__header">
                <h6>Communities</h6>
              </div>
              <div className="marketplace__slider__cont">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={"auto"}
                  spaceBetween={15}
                  navigation={true}
                >
                  {communities.map((item, index) => (
                    <SwiperSlide>
                      <Card
                        title={item.title}
                        image={item.image}
                        index={index}
                        btnTitle="View"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
          <div className="marketplace__slider_sep"></div>
          <div className="marketplace__slider">
            <div className="custom__card">
              <div className="custom__card__header">
                <h6>More Suggestions</h6>
              </div>
              <div className="marketplace__slider__cont">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={"auto"}
                  spaceBetween={15}
                  navigation={true}
                >
                  {suggestions.map((item, index) => (
                    <SwiperSlide>
                      <Card
                        title={item.title}
                        image={item.image}
                        index={index}
                        btnTitle="View"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Sidebar = ({ communities }) => {
  return (
    <div className="dashboard__child2">
      <div>
        <h5 className="mb-0">Communities</h5>
        <div className="dashboard__filters">
          <div className="input__filter w-100">
            <img
              loading="lazy"
              decoding="async"
              src={search}
              alt=""
            />
            <input
              type="text"
              className="w-100"
              placeholder="Search community"
            />
          </div>
        </div>
        <div className="community__feed__discover mb-1">
          <div className="custom__card">
            <div className="d-flex mb-2 align-items-center">
              <div className="icon__wrapper">
                <i className="fa fa-feed"></i>
              </div>
              <p>Your feed</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="icon__wrapper">
                <i className="fa fa-compass"></i>
              </div>
              <p>Discover</p>
            </div>
            <div className="community__feed__discover__sep"></div>
            <button className="loginBtn">Create community</button>
          </div>
        </div>
        <div className="community__manage">
          <div className="custom__card">
            <h6 className="mb-3">Manage community</h6>
            <div className="community__side__card">
              <img
                loading="lazy"
                decoding="async"
                src={communities[0]?.image}
                alt=""
              />
              <div className="community__side__card__content">
                <p>{communities[0]?.title}</p>
                <span>Last active 1 hour ago</span>
              </div>
            </div>
            <div className="community__feed__discover__sep"></div>
            <h6 className="mb-3">Joined communities</h6>
            <div>
              <div className="community__side__card mb-2">
                <img
                  loading="lazy"
                  decoding="async"
                  src={communities[1]?.image}
                  alt=""
                />
                <div className="community__side__card__content">
                  <p>{communities[1]?.title}</p>
                  <span>Last active 1 hour ago</span>
                </div>
              </div>
              <div className="community__side__card">
                <img
                  loading="lazy"
                  decoding="async"
                  src={communities[2]?.image}
                  alt=""
                />
                <div className="community__side__card__content">
                  <p>{communities[2]?.title}</p>
                  <span>Last active 1 hour ago</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Card = ({ index, title, image, btnTitle }) => {
  const navigate = useNavigate()
  return (
    <div
      className="marketplace__card custom__card"
      key={index}
    >
      <div className="marketplace__card__img">
        <img
          loading="lazy"
          decoding="async"
          src={image}
          alt=""
        />
      </div>
      <div className="marketplace__card__body">
        <p className="marketplace__card__title">{title}</p>
        <button
          onClick={() => navigate.push("/community-forum")}
          className="loginBtn"
        >
          {btnTitle}
        </button>
      </div>
    </div>
  )
}

export default CommunitySection
