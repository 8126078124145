import "./login.css"
import { Link, useNavigate } from "react-router-dom"
import Form from "react-bootstrap/Form"
import logo from "../../../assets/logo.png"

function LoginSection() {
  const navigate = useNavigate()
  const loginHandle = (e) => {
    e.preventDefault()
    navigate.push("/dashboard")
  }
  return (
    <>
      <div className="loginWrapper d-flex align-items-center justify-content-center">
        <div className="loginDiv">
          <h1 className="logo text-center">
            <img
              className="loginLogo"
              src={logo}
            />
          </h1>
          <br />
          <br />
          <Form
            className="mt-3"
            onSubmit={loginHandle}
          >
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="fw-bolder">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                required
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <p className="text-center">
              <button
                type="submit"
                className="loginBtn"
              >
                Login
              </button>
            </p>
            <p className="text-center">
              <Form.Text className="text-muted">
                <Link to={"/forget-password"}>Forgot Password?</Link>
              </Form.Text>
            </p>
          </Form>
          <div className="loginSocial d-flex align-items-center justify-content-center mt-4">
            <i className="fa-brands fa-google m-2 fs-0 googleLogo"></i>
            <i className="fa-brands fa-apple m-2 fs-0 appleLogo"></i>
            <i className="fa-brands fa-twitter m-2 fs-0 twitterLogo"></i>
          </div>
          <p className="text-center mt-4">
            <Form.Text className="text-muted">
              Don't have an account?{" "}
              <Link to={"/register"}>Create new one</Link>
            </Form.Text>
          </p>
        </div>
      </div>
    </>
  )
}

export default LoginSection
