import './hero.css'
import Header from '../header/header'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='home_hero'>
      <Header />
      <div className="home_hero_section p_tb">
        <p className='hero_title'>The Film Industry <span>Reimagined.</span></p>
        <p className='hero_subtitle'>Make a film the Dreamby way.</p>
        <Link to='/register' className='home_btn'>Sign Up Now</Link>
        <p className='scroll_down'><i className="fa fa-arrow-left"></i> SCROLL DOWN</p>
      </div>
    </div>
  )
}

export default Hero