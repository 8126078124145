import { useEffect } from "react";
import RegisterSection from "../components/dashboard/register_section/register";

function Register() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <RegisterSection/>
        </>
    )
}

export default Register