import search from "../../../../assets/search.svg"

const ChatLeft = ({ messages, chatShow, setChatShow, selectedChatHandle }) => {
  return (
    <div className={`chat__child1 ${chatShow.chatLeft}`}>
      <div className="custom__card mb-3">
        <div className="chat__list__search">
          <img
            src={search}
            alt=""
          />
          <input
            type="text"
            placeholder="Search names..."
          />
        </div>
      </div>
      <div className="chat__list">
        <div className="custom__card">
          {messages.map((i, index) => (
            <>
              <div
                className="chat__list__card"
                key={index}
                onClick={() => [
                  setChatShow((prev) => ({
                    chatLeft: "",
                    chatCenter: "chat__child2__display_",
                    ChatRight: "",
                  })),
                  selectedChatHandle(index),
                ]}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ overflow: "hidden", position: "relative" }}
                >
                  <img
                    src={i.image}
                    alt=""
                  />
                  {i.isOnline && (
                    <div
                      className="chat__status__online"
                      style={{ background: "#34D859" }}
                    ></div>
                  )}
                  <div className="chat__content">
                    <span>{i.name}</span>
                    <p>
                      {i.messages[i.messages.length - 1].type === "right"
                        ? `You: ${i.messages[i.messages.length - 1].message}`
                        : i.messages[i.messages.length - 1].message}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end flex-column">
                  <p className="chat__time">
                    {i.messages[i.messages.length - 1].time}
                  </p>
                  {i.unseen !== 0 && <p className="chat__count">{i.unseen}</p>}
                </div>
              </div>
              <div className="chat__list__sep"></div>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChatLeft
