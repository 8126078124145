import './leaderboard.css'
import Modal from "react-bootstrap/Modal";

const LeaderboardModal = ({ show, onHide, users }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='leaderboard_modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Leaderboard
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="custom__card">
                    <div className="top__community__card__body">
                        {users.map((item, index) => (
                            <div className="top__community__item_cont">
                                <div className="top__community__item">
                                    <div className='top__community__rank' style={{ width: '50px' }}>
                                        <p>#{index + 1}</p>
                                    </div>
                                    <div className="community__side__card">
                                        <img src={item.image} alt="" />
                                        <div className='community__side__card__content'>
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <button className="loginBtn">{1000 + users.length - index} XP</button>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LeaderboardModal