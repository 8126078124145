import './leaderboard.css'
import { useState } from 'react'
import LeaderboardModal from '../../leaderboard/leaderboard'
import user1 from '../../../../assets/faces/1.jpg'
import user2 from '../../../../assets/faces/2.jpg'
import user3 from '../../../../assets/faces/3.jpg'
import user4 from '../../../../assets/faces/4.jpg'
import user5 from '../../../../assets/faces/5.jpg'
import user6 from '../../../../assets/faces/6.jpg'
import user7 from '../../../../assets/faces/7.jpg'

const users = [
    {
        name: "Brian Tubalt",
        image: user1
    },
    {
        name: "Kerry Ingleton",
        image: user2
    },
    {
        name: "Sarah Keale",
        image: user3
    },
    {
        name: "Lance Potter",
        image: user4
    },
    {
        name: "Edi Kamau",
        image: user5
    },
    {
        name: "Toby McIntyre",
        image: user6
    },
    {
        name: "Hannah Field",
        image: user7
    }
]

const Leaderboard = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <div className='leaderboard__wrapper'>
                <div className="custom__card">
                    <div className="custom__card__header">
                        Leaderboard
                    </div>
                    <div className="leaderboard__users">
                        <div className="leaderborad__img">
                            <img src={user1} alt="" />
                            <p className='leaderboard__rank'>#1</p>
                            <p>1000 XP</p>
                        </div>
                        <div className="leaderborad__img">
                            <img src={user2} alt="" />
                            <p className='leaderboard__rank'>#2</p>
                            <p>800 XP</p>
                        </div>
                        <div className="leaderborad__img">
                            <img src={user3} alt="" />
                            <p className='leaderboard__rank'>#3</p>
                            <p>617 XP</p>
                        </div>
                    </div>
                    <div className="text-end"><a onClick={() => setModalShow(true)}>View Leaderboard</a></div>
                </div>
            </div>
            <LeaderboardModal
                users={users}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Leaderboard