import "./jobs.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import user1 from "../../../assets/jobs/1.jpg"
import user2 from "../../../assets/jobs/2.jpg"
import user3 from "../../../assets/jobs/3.jpg"
import user4 from "../../../assets/jobs/4.png"
import user5 from "../../../assets/jobs/5.jpg"
import { useLocation } from "react-router-dom"
import { useMemo } from "react"

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function JobsSection() {
  let query = useQuery()

  return (
    <>
      <div className="jobWrapper">
        <div className="d-flex">
          <Container fluid>
            <Row>
              <Col
                lg={2}
                md={12}
                xs={12}
              >
                <h5 className="fw-bold www">Video</h5>

                <Container fluid>
                  <Row>
                    <Col
                      lg={12}
                      md={5}
                      xs={6}
                    >
                      <p className="cursorStyle">About</p>
                      <p className="cursorStyle">Team</p>
                      <p className="cursorStyle">Story</p>
                    </Col>
                    <Col
                      lg={12}
                      md={5}
                      xs={6}
                    >
                      <p className="cursorStyle">Why</p>
                      <p className="cursorStyle">FAQ</p>
                      <p className="cursorStyle">Join</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col
                lg={10}
                md={12}
                xs={12}
              >
                {query.get("type") === "project" && (
                  <>
                    <h3 className="fw-bold jHead">Creator Team</h3>
                    <Container fluid>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <div className="jtCard mb-3 p-3">
                            <p className="text-center">
                              <img
                                className="jImg"
                                src={user1}
                                alt=""
                              />
                            </p>
                            <h6 className="text-center fw-bold jtName">
                              Eszter Kendrick
                            </h6>
                            <h6 className="text-center jtName">
                              New York, USA
                            </h6>
                            <h6 className="text-center jtName">
                              Director | <span className="blue">Started</span>
                            </h6>
                          </div>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <div className="jtCard mb-3 p-3">
                            <p className="text-center">
                              <img
                                className="jImg"
                                src={user2}
                                alt=""
                              />
                            </p>
                            <h6 className="text-center fw-bold jtName">
                              Louisa Daele
                            </h6>
                            <h6 className="text-center jtName">
                              California, USA
                            </h6>
                            <h6 className="text-center jtName">
                              Writer | <span className="blue">Started</span>
                            </h6>
                          </div>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <div className="jtCard mb-3 p-3">
                            <p className="text-center">
                              <img
                                className="jImg"
                                src={user3}
                                alt=""
                              />
                            </p>
                            <h6 className="text-center fw-bold jtName">
                              Temo Branden
                            </h6>
                            <h6 className="text-center jtName">Texas, USA</h6>
                            <h6 className="text-center jtName">
                              Producer |{" "}
                              <span className="green">Completed</span>
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </>
                )}

                {query.get("type") === "job" && (
                  <>
                    <h3 className="fw-bold bHead">Brand Team</h3>
                    <Container fluid>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <div className="jtCard mb-3 p-3">
                            <p className="text-center">
                              <img
                                className="jImg"
                                src={user4}
                                alt=""
                              />
                            </p>
                            <h6 className="text-center fw-bold jtName">
                              Nick Jones
                            </h6>
                            <h6 className="text-center jtName">Montana, USA</h6>
                            <Container fluid>
                              <Row>
                                <Col
                                  lg={6}
                                  md={6}
                                  xs={6}
                                >
                                  <p className="btAIR fw-bold">
                                    <i className="bi bi-chat-left-text"></i>
                                  </p>
                                </Col>
                                <Col
                                  lg={6}
                                  md={6}
                                  xs={6}
                                >
                                  <p className="btAIL fw-bold">
                                    <i className="bi bi-telephone"></i>
                                  </p>
                                </Col>
                              </Row>
                            </Container>
                            <h6 className="text-center jtName">
                              Commissioner |{" "}
                              <span className="green">Completed</span>
                            </h6>
                          </div>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                        >
                          <div className="jtCard mb-3 p-3">
                            <p className="text-center">
                              <img
                                className="jImg"
                                src={user5}
                                alt=""
                              />
                            </p>
                            <h6 className="text-center fw-bold jtName">
                              Judit Shoer
                            </h6>
                            <h6 className="text-center jtName">
                              New York, USA
                            </h6>
                            <Container fluid>
                              <Row>
                                <Col
                                  lg={6}
                                  md={6}
                                  xs={6}
                                >
                                  <p className="btAIR fw-bold">
                                    <i className="bi bi-chat-left-text"></i>
                                  </p>
                                </Col>
                                <Col
                                  lg={6}
                                  md={6}
                                  xs={6}
                                >
                                  <p className="btAIL fw-bold">
                                    <i className="bi bi-telephone"></i>
                                  </p>
                                </Col>
                              </Row>
                            </Container>
                            <h6 className="text-center jtName">
                              Creative director |{" "}
                              <span className="green">Completed</span>
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </>
                )}

                <h4 className="fw-bold bHead">Invite Your Friends</h4>
                <p className="jDesc">
                  Add another team member to this project. Or invite a new team
                  member to your brand
                </p>

                <div className="itForm">
                  {/*<Container fluid>*/}
                  <Row>
                    <Col
                      lg={6}
                      md={6}
                      xs={12}
                    >
                      <div className="tSelect">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Team member"
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={12}
                    >
                      <div className="tSelect">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="role"
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      xs={12}
                    >
                      <p className="tInvite">
                        <Button
                          className="tInviteBtn"
                          variant="secondary"
                          style={{ background: "#C51453", border: 0 }}
                        >
                          Invite
                        </Button>
                      </p>
                    </Col>
                  </Row>
                  {/*</Container>*/}
                </div>

                {/* <h4 className="fw-bold bHead">Timeline</h4>
                  <p className="jDesc">
                    This is the complete project timeline of tasks that have
                    been completed.
                  </p>

                  <h4 className="fw-bold bHead checkCircle">
                    <i className="bi bi-check-circle-fill"></i> Completed (50
                    hours)
                  </h4>
                  <p className="jDesc">
                    These are all jobs that have been completed
                  </p>

                  <div className="tTasks">
                    <Container>
                      <Row>
                        <Col
                          lg={3}
                          md={3}
                          xs={3}
                        >
                          <h6 className="tTHead fw-bold">
                            Video Project Scoping
                          </h6>
                          <p className="tTDesc">Production Management</p>
                        </Col>
                        <Col
                          lg={3}
                          md={3}
                          xs={3}
                        >
                          <p className="text-center tTUserSpan">
                            <img
                              src={user4}
                              alt=""
                              className="tTUImg"
                            />
                            <span className="tTUserSpan fw-bold">Samuel</span>
                          </p>
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          xs={2}
                        >
                          <h4 className="tTCheck text-center">
                            <i className="bi bi-check-circle-fill checkCircle"></i>
                          </h4>
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          xs={2}
                        >
                          <h6 className="tTHead fw-bold text-center">1.0</h6>
                          <p className="tTDesc text-center">Hours</p>
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          xs={2}
                        >
                          <h6 className="tTHead fw-bold text-center">$ 125</h6>
                          <p className="tTDesc text-center">USD</p>
                        </Col>
                      </Row>
                    </Container>
                  </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}
export default JobsSection
