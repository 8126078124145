import './sidebar.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import logo from '../../../assets/headerlogo.png'

const Sidebar = ({ sidebarDisplay, setSidebarDisplay }) => {
    useEffect(() => {
        setSidebarDisplay(false)
    }, [window.location.pathname])

    return (
        <div className={`admin__sidebar ${sidebarDisplay && 'admin__sidebar__left'}`}>
            <div className="sidebar__header">
                <div className="sidebar__logo">
                    <Link to='/dashboard'>
                        <img src={logo} alt="sidebar__logo" />
                    </Link>
                </div>
                <div className="sidebar__close"
                    onClick={() => setSidebarDisplay(false)}
                >
                    <i className='fa fa-close'></i>
                </div>
            </div>
            <div className="sidebar__links">
                <ul>
                    <li><Link to="/dashboard"><i className='fa fa-home'></i><span>Dashboard</span></Link></li>
                    <li><Link to="/content-management-files"><i className='fa fa-folder'></i><span>Uploads</span></Link></li>
                    <li><Link to="/marketplace"><i className='fa fa-building-columns'></i><span>Marketplace</span></Link></li>
                    <li><Link to="/community"><i className='fa fa-users'></i><span>Community</span></Link></li>
                    <li><Link to="/analytics"><i className='fa fa-chart-line'></i><span>Analytics</span></Link></li>
                    <li><Link to="/recruitment"><i className='fa fa-user'></i><span>Jobs</span></Link></li>
                    <li><Link to="/chat"><i className='fa fa-comment'></i><span>Chat</span></Link></li>
                    <li><Link to="/wallet"><i className='fa fa-wallet'></i><span>Wallet</span></Link></li>
                    <li><Link><i className='fa fa-right-from-bracket'></i><span>Sign Out</span></Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar