const AllFriends = ({ users }) => {
    return (
        <div className="friends__child2">
            <div className="top__community w-100">
                <div className="custom__card">
                    <div className="custom__card__header"><h6>All Friends</h6></div>
                    <div className="marketplace__slider__cont">
                        <div className="top__community__card__body">
                            {users.map((item, index) => (
                                <div className="top__community__item_cont">
                                    <div className="top__community__item">
                                        <div className="community__side__card">
                                            <img src={item.image} alt="" />
                                            <div className='community__side__card__content'>
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="loginBtn">View</button>
                                </div>
                            )).slice(5)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllFriends