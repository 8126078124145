import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import JobsSection from "../components/dashboard/jobs/jobs";

function Jobs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <Header/>
            <JobsSection/>
        </>
    )
}

export default Jobs