import "./splash_banner.css"
import { useNavigate } from "react-router-dom"
import backIcon from "../../../assets/splash/back.svg"
import poster from "../../../assets/splash/poster.png"
import rIcon from "../../../assets/splash/r.svg"
import hdIcon from "../../../assets/splash/hd.svg"
import ccIcon from "../../../assets/splash/cc.svg"
import dolbyIcon from "../../../assets/splash/dolby.svg"
import likeIcon from "../../../assets/splash/thumb.svg"
import playIcon from "../../../assets/splash/play.svg"
import euroIcon from "../../../assets/splash/euro.svg"
import plusIcon from "../../../assets/splash/plus.svg"
import shareIcon from "../../../assets/splash/share.svg"
import trailer from "../../../assets/splash/trailer.svg"
import teaser from "../../../assets/splash/teaser.svg"
import watchParty from "../../../assets/splash/party.svg"
import fortnite from "../../../assets/splash/fortnite.svg"
import bodvar from "../../../assets/splash/bodvar.svg"
import line1 from "../../../assets/splash/line1.svg"
import line2 from "../../../assets/splash/line2.svg"
import line3 from "../../../assets/splash/line3.svg"
import line4 from "../../../assets/splash/line4.svg"
import line5 from "../../../assets/splash/line5.svg"

function VideoSplashBanner() {
  const navigate = useNavigate()
  return (
    <div className="homeFooterWrapper pb">
      <img
        src={line1}
        alt=""
        className="line1"
      />
      <img
        src={line2}
        alt=""
        className="line2"
      />

      <div
        className="splash_back"
        onClick={() => navigate.push("/dashboard")}
      >
        <div className="back_icon">
          <img
            src={backIcon}
            alt="Icon"
          />
        </div>
        <span>Back</span>
      </div>
      <div className="splash_banner">
        <div>
          <img
            src={poster}
            alt=""
            className="poster_img"
          />
          <img
            src={line4}
            alt=""
            className="line4"
          />
          <img
            src={line5}
            alt=""
            className="line5"
          />
          <Vote />
        </div>
        <div>
          <div className="splash_title">Swipe Fever</div>
          <Details />
          <LikesViews />
          <Description />
          <Genre />
          <WatchAds />
          <Trailer />
          <Party />
          <Vote />
        </div>
      </div>
    </div>
  )
}

const Details = () => {
  return (
    <div className="splash_video_details">
      <span>1 hr 30 min</span>
      <img
        src={rIcon}
        alt=""
      />
      <span>2021</span>
      <img
        src={hdIcon}
        alt=""
      />
      <img
        src={dolbyIcon}
        alt=""
      />
      <img
        src={ccIcon}
        alt=""
      />
      <span>English</span>
    </div>
  )
}

const LikesViews = () => {
  return (
    <div className="splash_likes_views">
      <div className="top_btn">Top 10</div>
      <div className="like_btn">
        <img
          src={likeIcon}
          alt=""
        />
      </div>
      <div>2.458.158</div>
      <span>(Views 2.908.531)</span>
    </div>
  )
}

const Description = () => {
  return (
    <div className="splash_video_desc">
      Valentine reluctantly enters the world of dating apps and gets sick with
      Swipe Fever as he obsessively tries to find the one.
    </div>
  )
}

const Genre = () => {
  return (
    <div className="splash_genre">
      <p>Comedy</p>
      <p>Romance</p>
    </div>
  )
}

const WatchAds = () => {
  return (
    <div className="splash_watch_ads">
      <div className="swa">
        <div>
          <div>Watch Free</div>
          <span>w/Ads*</span>
        </div>
        <div className="swa_icon">
          <img
            src={playIcon}
            alt=""
          />
        </div>
        <p>*Part of the watch and earn program</p>
      </div>
      <div className="swa">
        <div>
          <div>No Ads</div>
        </div>
        <div className="swa_icon">
          <img
            src={euroIcon}
            alt=""
          />
        </div>
      </div>
      <div className="swa_2">
        <div>
          <div className="swa_icon">
            <img
              src={plusIcon}
              alt=""
            />
          </div>
          <span>Watch Later</span>
        </div>
        <div>
          <div className="swa_icon">
            <img
              src={shareIcon}
              alt=""
            />
          </div>
          <span>Share</span>
        </div>
      </div>
    </div>
  )
}

const Trailer = () => {
  return (
    <div className="splash_trailer">
      <img
        src={line3}
        alt=""
        className="line3"
      />
      <div>
        <img
          src={teaser}
          alt=""
        />
        <span>Teaser Video (30 sec)</span>
      </div>
      <div>
        <img
          src={trailer}
          alt=""
        />
        <span>Official Trailer Video (2 min)</span>
      </div>
    </div>
  )
}

const Party = () => {
  return (
    <div className="splash_party">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: 14,
            marginBottom: 10,
          }}
        >
          <img
            src={watchParty}
            alt=""
          />
          <span style={{ marginTop: 9 }}>Watch Party</span>
        </div>
        <p>
          Join us, watch with your friends, and win swag this Valentine’s Day!
        </p>
      </div>
      <div>
        <br />
        <p>This event is brought to you by</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: 10,
          }}
        >
          <img
            src={fortnite}
            alt=""
          />
          <img
            src={plusIcon}
            alt=""
          />
          <img
            src={bodvar}
            alt=""
          />
        </div>
      </div>
      <div>
        <p>Want to party in WebXR?</p>
        <span>Create your Fortnite Avatar and join here!</span>
      </div>
    </div>
  )
}

const Vote = () => {
  return (
    <div className="splash_vote">
      <p>Show some love with your vote! </p>
      <div className="vote_thumb">
        <p>VOTE</p>
        <img
          src={likeIcon}
          alt=""
        />
      </div>
    </div>
  )
}

export default VideoSplashBanner
