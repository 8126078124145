import { useEffect } from "react";
import Header from "../components/dashboard/header/header"
import ForumSection from "../components/dashboard/forum/forum"
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

const Forum = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <div className="dashboard__panel">
                <Header />
                <div className='dashboard'>
                    <div className="dashboard__cont community__forum">
                        <ForumSection />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forum