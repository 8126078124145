import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import unseen from "../../../../assets/marketplace/unseen.jpg"
import vanishing from "../../../../assets/marketplace/vanishing.jpg"
import junior from "../../../../assets/marketplace/junior.jpg"
import summit from "../../../../assets/marketplace/summit.jpg"
import sg50 from "../../../../assets/marketplace/50sg.jpg"
import _300 from "../../../../assets/marketplace/300.jpg"
import arrival from "../../../../assets/marketplace/arrival.jpg"
import borat from "../../../../assets/marketplace/borat.jpg"
import boyhood from "../../../../assets/marketplace/boyhood.jpg"
import bswan from "../../../../assets/marketplace/bswan.jpg"

const projects = [
  {
    title: "The Unseen",
    image: unseen,
    description:
      "A supernatural teen drama series about a group of high school students who have the ability to see and communicate with ghosts and use their powers to help restless spirits cross over and solve the mysteries surrounding their deaths while facing powerful and dangerous forces.",
  },
  {
    title: "The Vanishing Forests",
    image: vanishing,
    description:
      "A documentary that explores the devastating effects of deforestation on the world's forests and wildlife, highlights successful conservation efforts, and serves as a call to action for viewers to become stewards of the planet and work towards a more sustainable future.",
  },
  {
    title: "Junior Chef ",
    image: junior,
    description:
      "A children's cooking show that showcases young talent while teaching young viewers how to cook healthy and delicious meals. Full of fun, culture and cuisines.",
  },
  {
    title: "Summit Seekers",
    image: summit,
    description:
      "A movie about a group of amateur climbers who embark on an epic journey to summit one of the world's most challenging peaks and face numerous obstacles and challenges along the way, testing their friendship, perseverance, and inner strength.",
  },
  {
    title: "Fifty Shades of Grey",
    image: sg50,
    description:
      "Literature student Anastasia Steele's life changes forever when she meets handsome, yet tormented, billionaire Christian Grey.",
  },
  {
    title: "300",
    image: _300,
    description:
      "King Leonidas of Sparta and a force of 300 men fight the Persians at Thermopylae in 480 B.C.",
  },
  {
    title: "Arrival",
    image: arrival,
    description:
      "A linguist works with the military to communicate with alien lifeforms after twelve mysterious spacecrafts appear around the world.",
  },
  {
    title: "Borat",
    image: borat,
    description:
      "Kazakh TV talking head Borat is dispatched to the United States to report on the greatest country in the world. With a documentary crew in tow, Borat becomes more interested in locating and marrying Pamela Anderson.",
  },
  {
    title: "Boyhood",
    image: boyhood,
    description:
      "The life of Mason, from early childhood to his arrival at college.",
  },
  {
    title: "Black Sawan",
    image: bswan,
    description:
      "A committed dancer struggles to maintain her sanity after winning the lead role in a production of Tchaikovsky's 'Swan Lake'.",
  },
]

const Projects = () => {
  return (
    <div className="dashboard__content__library">
      <div className="marketplace__slider">
        <div className="custom__card">
          <div className="custom__card__header">
            <span>Projects</span>
            <Link to={"/marketplace"}>View all projects</Link>
          </div>
          <div className="marketplace__slider__cont">
            <Swiper
              modules={[Navigation]}
              slidesPerView={"auto"}
              spaceBetween={8}
              navigation={true}
            >
              {projects.map((item, index) => (
                <SwiperSlide>
                  <Card
                    title={item.title}
                    image={item.image}
                    index={index}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

const Card = ({ index, title, image }) => {
  return (
    <Link
      to="/jobs?type=project"
      className="marketplace__card custom__card"
      key={index}
    >
      <div className="marketplace__card__img">
        <img
          decoding="async"
          loading="lazy"
          src={image}
          alt=""
        />
      </div>
      <div className="marketplace__card__body">
        <p className="marketplace__card__title">{title}</p>
      </div>
    </Link>
  )
}


export default Projects
