import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import MissionSection from "../components/dashboard/mission/mission_section";

function Mission() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <Header/>
            <MissionSection/>
        </>
    )
}

export default Mission