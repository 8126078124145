import "./analytics.css"
import TopUpload from "../top_upload/top_upload"
import Wallet from "../wallet/wallet"
import Views from "../views/views"
import Affiliate from "../affiliate/affiliate"
import Upload from "../upload/upload"

const Analytics = () => {
  return (
    <div className="dashboard__analytics">
      <div>
        <Upload />
        <TopUpload />
      </div>
      <div>
        <Wallet />
        <Views />
        <Affiliate />
      </div>
    </div>
  )
}

export default Analytics
