import "./offerr.css"
import Carousel from "react-multi-carousel"
import offer2 from "../../../assets/offer2.png"
import offer3 from "../../../assets/offer3.png"
import offer4 from "../../../assets/offer4.png"
import offer5 from "../../../assets/offer5.png"
import offer6 from "../../../assets/offer6.png"
import line6 from "../../../assets/splash/line6.svg"
import line7 from "../../../assets/splash/line7.svg"


function VideoSplashOffer() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1025 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  }

  return (
    <>
      <div className="homeFooterWrapper">
        <img
          src={line6}
          alt=""
          className="line6"
        />
        <img
          src={line7}
          alt=""
          className="line7"
        />
        <h5 className="footerHead fw-bold">Offers</h5>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
              <h6 className="vscSubHead1Highlighted fw-bold mt-4">
                Collectibles
              </h6>
              <h6 className="vscSubHead2 fw-bold mt-4 ">Fortnite</h6>
              <h6 className="vscSubHead2 fw-bold mt-4 ">Swag</h6>
            </div>
            <hr className="seperator" />
            <Carousel
              responsive={responsive}
              style={{ width: "100%" }}
            >
              <div className="vscCateItem">
                <div className="offerdetail">
                  <p className="text-center offerDetailText mt-4 mb-4">
                    Limited Time Auction
                  </p>
                  <p className="text-center offerDetailText">
                    Starts 2023/01/02 1200
                  </p>
                  <p className="text-center offerDetailText">
                    Expires 2023/15/02 2400
                  </p>
                </div>
                <p className="vscCateItemDesc text-center"></p>
              </div>
              <div className="vscCateItem">
                <p>
                  <img
                    className="offerImg"
                    src={offer6}
                  />
                </p>
                <p className="vscCateItemDesc text-center">3D Logo</p>
              </div>
              <div className="vscCateItem">
                <p>
                  <img
                    className="offerImg"
                    src={offer5}
                  />
                </p>
                <p className="vscCateItemDesc text-center">Val’s Mug</p>
              </div>
              <div className="vscCateItem">
                <p>
                  <img
                    className="offerImg"
                    src={offer4}
                  />
                </p>
                <p className="vscCateItemDesc text-center">Lava Lamp</p>
              </div>
              <div className="vscCateItem">
                <p>
                  <img
                    className="offerImg"
                    src={offer2}
                  />
                </p>
                <p className="vscCateItemDesc text-center">DOPE AF</p>
              </div>
              <div className="vscCateItem">
                <p>
                  <img
                    className="offerImg"
                    src={offer3}
                  />
                </p>
                <p className="vscCateItemDesc text-center">Love</p>
              </div>
            </Carousel>
          </div>
          <div></div>
        </div>
      </div>
    </>
  )
}

export default VideoSplashOffer
