import { useNavigate } from 'react-router-dom'
import affiliateIcon from '../../../../assets/affiliateIcon.svg'

const Affiliate = () => {
    const navigate = useNavigate()
    return (
        <div className="custom__card affiliate__cont" onClick={() => navigate.push('/analytics')}>
            <div className="custom__card__header">
                <div className='card__top__wrapper'>
                    <img src={affiliateIcon} alt="" />
                    <p>Top Affiliate Hits</p>
                </div>
            </div>
            <div className='affiliate__table'>
                <table>
                    <tbody>
                        <tr>
                            <td>Swipe Fever</td>
                            <td><a>43.987</a></td>
                        </tr>
                        <tr>
                            <td>Love Game</td>
                            <td><a>12.553</a></td>
                        </tr>
                        <tr>
                            <td>All In</td>
                            <td><a>18.553</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>Results since 0830 Today</p>
        </div>
    )
}

export default Affiliate