import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import VideoSplashBanner from "../components/dashboard/splash_banner/splash_banner";
import VideoSplashCommunity from "../components/dashboard/splash_comunity/community";
import VideoSplashOffer from "../components/dashboard/splash_offer/offer";
import VideoSplashFooter from "../components/dashboard/splash_footer/splash_footer";
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

function VideoSplash() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <Header />
            <VideoSplashBanner />
            <VideoSplashOffer />
            <VideoSplashCommunity />
            <VideoSplashFooter />
        </>
    )
}

export default VideoSplash