import { useEffect } from "react";
import Header from "../components/dashboard/header/header"
import MarketplaceSection from "../components/dashboard/marketplace/marketplace"
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

const Marketplace = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <div className="dashboard__panel">
                <Header />
                <div className='dashboard'>
                    <div className="dashboard__cont">
                        <MarketplaceSection />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Marketplace
