import "./splash_footer.css"
import staring1 from "../../../assets/staring1.png"
import staring2 from "../../../assets/staring2.png"
import staring3 from "../../../assets/staring3.png"

function VideoSplashFooter() {
  return (
    <>
      <div className="homeFooterWrapper">
        <h5 className="footerHead fw-bold">Details</h5>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-7">
              <h6 className="footerSubHead fw-bold mt-4">Starring</h6>
              <table className="starringTable mt-5">
                <tr className="mt-3">
                  <td className="staringImgTd">
                    <img
                      src={staring1}
                      alt=""
                      className="staringImg"
                    />
                  </td>
                  <td className="staringText1">Valentine ‘Val’</td>
                  <td className="staringText2">Finlay MacMillan</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={staring2}
                      alt=""
                      className="staringImg"
                    />
                  </td>
                  <td className="staringText1">Drew</td>
                  <td className="staringText2">Tessa Bonham Jones</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={staring3}
                      alt=""
                      className="staringImg"
                    />
                  </td>
                  <td className="staringText1">John</td>
                  <td className="staringText2">Francis Lovehall</td>
                </tr>
              </table>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5">
              <h6 className="footerSubHead fw-bold mt-4">Producers</h6>
              <table className="producerTabele">
                <tr>
                  <td className="producerText1">Executive Producer</td>
                  <td className="producerText2">John Staal</td>
                </tr>
                <tr>
                  <td className="producerText1">Producer</td>
                  <td className="producerText2">Matthew Critchfield</td>
                </tr>
                <tr>
                  <td className="producerText1">Producer</td>
                  <td className="producerText2">Mark Forstater</td>
                </tr>
                <tr>
                  <td className="producerText1">Producer</td>
                  <td className="producerText2">Nathan Neuman</td>
                </tr>
              </table>
              <h6 className="footerSubHead fw-bold mt-4">Director</h6>
              <table className="producerTabele">
                <tr>
                  <td className="producerText1">Directed by</td>
                  <td className="producerText2">Nathan Neuman</td>
                </tr>
              </table>
              <h6 className="footerSubHead fw-bold mt-4">Writers</h6>
              <table className="producerTabele">
                <tr>
                  <td className="producerText1">Written by</td>
                  <td className="producerText2">Sam Jordan Dudeck</td>
                </tr>
                <tr>
                  <td className="producerText1">Written by</td>
                  <td className="producerText2">Nathan Neuman</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoSplashFooter
