import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import lineUp from '../../../../assets/chart_up.svg';

// const RADIAN = Math.PI / 180;
const COLORS1 = ["#C51453", "#FFB238"];
const COLORS2 = ["#921D72", "#0C73BC", "#F89E3C", "#BBAF30"];
const showAxis = window.innerWidth > 991 ? true : false;

const Film = () => {
    const filmData = [
        { clicks: '10', film: "Spiderman" },
        { clicks: '16', film: "James bond" },
        { clicks: '10', film: "Avengers" },
        { clicks: '12', film: "Batman" },
        { clicks: '13', film: "Hungers" },
        { clicks: '11', film: "Hobit" },
        { clicks: '10', film: "Superman" },
        { clicks: '21', film: "Terminator" },
        { clicks: '11', film: "Witcher" },
        { clicks: '23', film: "GOT" }
    ];

    const data_1 = [
        { name: "Cost", value: 100 },
        { name: "Revenue", value: 140 }
    ];

    const data_2 = [
        { name: "Ads", value: 200 },
        { name: "Views", value: 20 },
        { name: "Shares", value: 50 },
        { name: "Donations", value: 80 }
    ];

    // const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
    //     const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //     const y = cy + radius * Math.sin(-midAngle * RADIAN);
    //     return (
    //         <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    //             {`$${value} `}
    //         </text>
    //     );
    // }

    return (
      <div className="graph__card film_investment">
        <div className="graph custom__card_header d-flex align-items-center">
          <h6>Film Investment</h6>
          <div>
            <span>Currency</span>
            <select>
              <option>$</option>
              <option>€</option>
              <option>£</option>
            </select>
          </div>
          <div>
            <span>Duration</span>
            <select>
              <option value="1">Last 30 days</option>
              <option value="2">Weekly</option>
              <option value="3">Monthly</option>
              <option value="4">Yearly</option>
            </select>
          </div>
        </div>
        <div className="graph__cont">
          <div className="custom__card graph__1">
            <div className="custom__card_header d-flex align-items-center justify-content-between">
              <span>Overall Film Clicks</span>
              <span>
                + 50{" "}
                <img
                  src={lineUp}
                  alt=""
                />
              </span>
            </div>
            <div
              className="marketplace__slider__cont"
              style={{ height: "calc(100% - 20px)" }}
            >
              <ResponsiveContainer
                width="100%"
                height="100%"
              >
                <LineChart data={filmData}>
                  {showAxis && (
                    <>
                      <XAxis dataKey="film" />
                    </>
                  )}
                  <Line
                    type="monotone"
                    dataKey="clicks"
                    stroke="#C51453"
                  />
                  {!showAxis && (
                    <Line
                      type="monotone"
                      dataKey="film"
                      stroke="#C51453"
                    />
                  )}
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="custom__card graph__2">
            <div className="custom__card_header text-center">
              <select>
                <option value="batman">Batman</option>
                <option value="superman">Superman</option>
                <option value="hitman">Hitman</option>
                <option value="avengers">Avengers</option>
              </select>
            </div>
            <div
              className="marketplace__slider__cont"
              style={{ height: "calc(100% - 27px)" }}
            >
              <ResponsiveContainer
                width="100%"
                height="100%"
              >
                <PieChart>
                  <Pie
                    data={data_1}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                  >
                    {data_1.map((entry, index) => (
                      <Cell
                        key={`cell - ${index}`}
                        fill={COLORS1[index % COLORS1.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="custom__card graph__3">
            <div className="custom__card_header">Revenue Type</div>
            <div
              className="marketplace__slider__cont"
              style={{ height: "calc(100% - 20px)" }}
            >
              <ResponsiveContainer
                width="100%"
                height="100%"
              >
                <PieChart>
                  <Pie
                    data={data_2}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                  >
                    {data_2.map((entry, index) => (
                      <Cell
                        key={`cell - ${index}`}
                        fill={COLORS2[index % COLORS2.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend
                    align="right"
                    layout="vertical"
                    verticalAlign="middle"
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Film