import { Link } from "react-router-dom"
import Form from "react-bootstrap/Form"
import logo from "../../../assets/logo.png"

function ForgotPasswordSection() {
  return (
    <>
      <div className="loginWrapper d-flex align-items-center justify-content-center">
        <div className="loginDiv">
          <h1 className="logo text-center">
            <img
              className="loginLogo"
              src={logo}
            />
          </h1>
          <Form className="mt-5">
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="fw-bolder">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <br />
            <p className="text-center">
              <button className="loginBtn">Forgot Password</button>
            </p>
          </Form>

          <p className="text-center mt-4">
            <Form.Text className="text-muted">
              Don't have an account?{" "}
              <Link to={"/register"}>Create new one</Link>
            </Form.Text>
          </p>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordSection
