import image1 from '../../../../assets/community/2.jpg'
import image2 from '../../../../assets/community/3.jpg'
import image3 from '../../../../assets/community/4.jpg'
import image4 from '../../../../assets/community/5.jpg'

const ChatRight = ({ user, chatShow, setChatShow, chatProfileShow, setChatProfileShow }) => {
  return (
    <div className={`chat__child3 ${chatProfileShow.chatRight} ${chatShow.ChatRight}`}>
      <div
        onClick={() => {
          setChatProfileShow(prev => ({
            chatCenter: 'chat__child2__display',
            chatRight: 'chat__child3__display',
          }))
          setChatShow((prev => ({
            chatLeft: '',
            chatCenter: 'chat__child2__display_',
            ChatRight: '',
          })))
        }}
        className="icon__wrapper custom__card">
        <i className="fa fa-close"></i>
      </div>
      <div className="chat__right__profile mt-3">
        <div className="custom__card">
          <div className="text-center">
            <img src={user?.image} alt="" />
            <div className="chat__username">{user?.name}</div>
          </div>
          <div className="chat__right__sep"></div>
          <div className="chat__media">
            <p>Media</p>
            <div className="chat__media__images">
              <img src={image1} alt="" />
              <img src={image2} alt="" />
              <img src={image3} alt="" />
              <img src={image4} alt="" />
            </div>
          </div>
          <div className="chat__right__sep"></div>
          <div className="chat__media">
            <p>About</p>
            <p style={{ fontSize: '13px' }}>Help people to build websites and apps + grow awareness in social media 🔥</p>
          </div>
          <div className="chat__right__sep"></div>
          <div className="chat__settings">
            <div>
              <i className="fa fa-trash"></i> <p><strong>Delete Chat</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ChatRight