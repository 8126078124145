import "./tips.css"
import logo from "../../../../assets/logo2.svg"

const Tips = () => {
  return (
    <div className="custom__card dreambird__tips">
      <div className="tips__logo">
        <img
          src={logo}
          alt=""
        />
      </div>
      <div className="tips__title">Dreambird Tips</div>
      <div className="tips__subtitle">
        Upgrade to Dreambird Soar to earn more tokens{" "}
      </div>
      <div className="tips__learn">Learn More</div>
    </div>
  )
}

export default Tips
