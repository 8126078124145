import { Link } from "react-router-dom"
import { ResponsiveContainer, LineChart, Line, XAxis, Tooltip } from "recharts"
import lineUp from "../../../../assets/chart_up.svg"

const showAxis = window.innerWidth > 991 ? true : false

const Work = () => {
  const filmData = [
    { clicks: "10", film: "Spiderman" },
    { clicks: "16", film: "James bond" },
    { clicks: "10", film: "Avengers" },
    { clicks: "12", film: "Batman" },
    { clicks: "13", film: "Hungers" },
    { clicks: "11", film: "Hobit" },
    { clicks: "10", film: "Superman" },
    { clicks: "21", film: "Terminator" },
    { clicks: "11", film: "Witcher" },
    { clicks: "23", film: "GOT" },
  ]

  return (
    <div className="graph__card">
      <div className="graph custom__card_header d-flex align-items-center">
        <h6>Assignment Earnings</h6>
        <div>
          <span>Currency</span>
          <select>
            <option>$</option>
            <option>€</option>
            <option>£</option>
          </select>
        </div>
        <div>
          <span>Duration</span>
          <select>
            <option value="1">Last 30 days</option>
            <option value="2">Weekly</option>
            <option value="3">Monthly</option>
            <option value="4">Yearly</option>
          </select>
        </div>
        <div>
          <span>Income</span>
          <select>
            <option value="1">All Type</option>
          </select>
        </div>
      </div>
      <div className="graph__cont">
        <div
          className="custom__card w-100"
          style={{ height: 275 }}
        >
          <div className="custom__card_header d-flex align-items-center justify-content-between">
            <span>Overall Fee Income</span>
            <span>
              + 50{" "}
              <img
                src={lineUp}
                alt=""
              />
            </span>
          </div>
          <div
            className="marketplace__slider__cont"
            style={{ height: "calc(100% - 20px)" }}
          >
            <ResponsiveContainer
              width="100%"
              height="100%"
            >
              <LineChart data={filmData}>
                {showAxis && (
                  <>
                    <XAxis dataKey="film" />
                  </>
                )}
                <Line
                  type="monotone"
                  dataKey="clicks"
                  stroke="#C51453"
                />
                {!showAxis && (
                  <Line
                    type="monotone"
                    dataKey="film"
                    stroke="#C51453"
                  />
                )}
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="text-end mt-1">
        <Link to="/wallet">View Wallet</Link>
      </div>
    </div>
  )
}

export default Work
