import './header.css'
import logo from '../../../assets/logo2.svg'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <div className="home_header">
            <div className="home_header_cont">
                <div className="home_header_left">
                    <div className="home_logo">
                        <img loading='lazy' decoding='async' src={logo} alt="" />
                        <span>DREAMBY</span>
                    </div>
                </div>
                <div className="home_header_right">
                    <Link to='/'>Home</Link>
                    <Link to='/contact'>Contact</Link>
                    <Link to="/register" className='home_btn'>Demo</Link>
                </div>
            </div>
        </div>
    )
}

export default Header