import "./modal.css"
import Modal from "react-bootstrap/Modal"
import { useState } from "react"

export default function DemoModal(props) {
  const [displayComponents, setDisplayComponents] = useState({
    home: true,
    filmMaker: false,
    filmFan: false,
  })

  return (
    <Modal
      {...props}
      size="md"
      className="demo_modal"
      centered
    >
      <Modal.Body>
        {displayComponents.home ? (
          <EntryText setDisplayComponents={setDisplayComponents} />
        ) : displayComponents.filmMaker ? (
          <FilmMaker />
        ) : (
          <FilmFan />
        )}
      </Modal.Body>
    </Modal>
  )
}

const EntryText = ({ setDisplayComponents }) => {
  return (
    <>
      <h6 className="text-center mt-2">
        Welcome to Dreambird, the platform to make and enjoy the future of
        entertainment together!
      </h6>
      <br />
      <div className="row">
        <div className="col-6 text-center">
          <a
            onClick={() =>
              setDisplayComponents((prev) => ({
                ...prev,
                home: false,
                filmMaker: true,
              }))
            }
          >
            Are you a filmmaker?
          </a>
        </div>
        <div className="col-6 text-center b_left">
          <a
            onClick={() =>
              setDisplayComponents((prev) => ({
                ...prev,
                home: false,
                filmFan: true,
              }))
            }
          >
            Are you a film fan?
          </a>
        </div>
      </div>
    </>
  )
}

const FilmMaker = () => {
  return (
    <div className="film_cont">
      <p>
        <strong>Are you a filmmaker?</strong>
      </p>
      <ul>
        <li>Do you have a film project you need to fund or find talent for?</li>
        <li>
          Do you have a film already made that you would like to upload for
          feedback or streaming?
        </li>
      </ul>
      <span>
        We will give you up to 85% of all money made by your film on our
        platform.
      </span>
      <br />
      <span>
        We will also help you to fund and produce your film in the first place.
      </span>
      <br />
      <span>
        We are not gatekeepers. The people that decide if your film gets watched
        here will be the Dreambird community themselves.
      </span>
      <Navigation />
    </div>
  )
}

const FilmFan = () => {
  return (
    <div className="film_cont">
      <p>
        <strong>Are you a film fan?</strong>
      </p>
      <ul>
        <li>
          We have a range of films for you to stream but also offer you an
          invitation to peel back the curtain, find out more, and even get
          involved with upcoming film projects.
        </li>
        <li>See what you fancy streaming now.</li>
        <li>
          Join a community to be connected with the kinds of films, work and
          film investments you’re into
        </li>
        <li>
          Or see what opportunities are there that you might like to get
          involved in – from funding to focus groups, or even invitations to
          become part of the team
        </li>
      </ul>
      <span>
        This is your community. You decide what to watch. You decide what gets
        made. You decide what the future looks like...
      </span>
      <Navigation />
    </div>
  )
}

const Navigation = ({ onHide }) => {
  return (
    <div className="d-flex align-items-center justify-content-between mt-2">
      <a href="/">Go Back to Site</a>
      <a
        onClick={() => [
          sessionStorage.setItem("entryModal", 0),
          window.location.reload(),
        ]}
      >
        Enter Demo
      </a>
    </div>
  )
}
