import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import WalletSection from "../components/dashboard/wallet/wallet";

function Wallet() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Header />
            <WalletSection />
        </>
    )
}

export default Wallet