import { useEffect } from 'react';
import Header from '../components/dashboard/header/header'
import ChatSection from '../components/dashboard/chat/chat'

const Chat = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="dashboard__panel">
            <Header />
            <ChatSection />
        </div>
    )
}

export default Chat