import './friends.css'
import { Link } from 'react-router-dom'
import user1 from '../../../../assets/faces/26.png'
import user2 from '../../../../assets/faces/27.png'
import user3 from '../../../../assets/faces/28.png'

const Friends = () => {
    return (
        <div className='friends__wrapper'>
            <div className="custom__card">
                <div className="custom__card__header">
                    <p>Friends</p>
                    <p className='add__friend'><i className='fa fa-add'></i></p>
                </div>
                <p className='follow__count'>Followers   200.407</p>
                <p className='follow__count'>Following   196.382</p>
                <div className="friends__users mb-2 mt-3">
                    <div className="friends__img">
                        <img src={user3} alt="" />
                    </div>
                    <div className="friends__img">
                        <img src={user1} alt="" />
                    </div>
                    <div className="friends__img">
                        <img src={user2} alt="" />
                    </div>
                </div>
                <div className="text-end"><Link to="/friends">View All Friends</Link></div>
            </div>
        </div>
    )
}

export default Friends