import "./header.css"
import { useState } from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import logo1 from "../../../assets/headerlogo.svg"
import logo2 from "../../../assets/headerlogo.png"
import profile from "../../../assets/profile.svg"
import hamburger from "../../../assets/hamburgerBars.svg"
import Sidebar from "../sidebar/sidebar"
import {
  communityIcon,
  dashboardIcon,
  marketplaceIcon,
  uploadIcon,
} from "../../../utils/icons"

const Header = () => {
  const [sidebarDisplay, setSidebarDisplay] = useState(false)
  const [darkMode, setDarkMode] = useState(false)

  const darkModeHandler = () => {
    const body = document.querySelector("body")
    if (body.classList.contains("dark")) {
      body.classList.remove("dark")
      setDarkMode(false)
      localStorage.removeItem("darkMode")
    } else {
      localStorage.setItem("darkMode", 1)
      body.classList.add("dark")
      setDarkMode(true)
    }
  }

  useEffect(() => {
    const body = document.querySelector("body")
    if (localStorage.getItem("darkMode")) {
      body.classList.add("dark")
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }, [])

  return (
    <>
      <Sidebar
        sidebarDisplay={sidebarDisplay}
        setSidebarDisplay={setSidebarDisplay}
      />
      <div className="dashboard__header">
        <div className="header__cont">
          <div
            className="header__hamburger"
            onClick={() => setSidebarDisplay(true)}
          >
            <img
              src={hamburger}
              alt=""
            />
          </div>
          <div className="header__logo header__logo__">
            <Link to="/dashboard">
              <img
                className="header_logo1"
                src={logo1}
                alt=""
              />
              <img
                className="header_logo2"
                src={logo2}
                alt=""
              />
            </Link>
          </div>
          <div className="header__logo header__logo__nav">
            <Link to="/dashboard">
              <img
                className="header_logo1"
                src={logo1}
                alt=""
              />
              <img
                className="header_logo2"
                src={logo2}
                alt=""
              />
            </Link>
          </div>
          <div className="header__nav">
            <Link
              to={"/dashboard"}
              className={`nav__item ${
                window.location.pathname === "/dashboard" && "active"
              }`}
            >
              <span>Dashboard</span>
              <div className="icon__wrapper">{dashboardIcon}</div>
            </Link>
            <Link
              to={"/content-management-files"}
              className={`nav__item ${
                window.location.pathname === "/content-management-files" &&
                "active"
              }`}
            >
              <span>Uploads</span>
              <div className="icon__wrapper">{uploadIcon}</div>
            </Link>
            <Link
              to={"/community"}
              className={`nav__item ${
                window.location.pathname === "/community" && "active"
              }`}
            >
              <span>Community</span>
              <div className="icon__wrapper">{communityIcon}</div>
            </Link>
            <Link
              to={"/marketplace"}
              className={`nav__item ${
                window.location.pathname === "/marketplace" && "active"
              }`}
            >
              <span>Marketplace</span>
              <div className="icon__wrapper">{marketplaceIcon}</div>
            </Link>
            <Link
              to={"/recruitment"}
              className={`nav__item ${
                window.location.pathname === "/recruitment" && "active"
              }`}
            >
              <span>Jobs</span>
              <div className="icon__wrapper">
                <i className="fa fa-user"></i>
              </div>
            </Link>
          </div>
          <div className="header__avatar">
            {darkMode && (
              <span onClick={darkModeHandler}>
                <i className="fa-regular fa-sun"></i>
              </span>
            )}
            {!darkMode && (
              <span onClick={darkModeHandler}>
                <i className="fa-regular fa-moon"></i>
              </span>
            )}
            <Link to="/profile">
              <img
                src={profile}
                alt=""
              />
              <span className="username">Samuel</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
