import "./cards.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import mission from "../../../assets/website/mission.png"
import community from "../../../assets/website/community.png"
import ai from "../../../assets/website/ai.jpg"
import interactivity from "../../../assets/website/interactivity.jpg"

const Cards = () => {
  return (
    <div className="home_cards">
      <div className="row w-100 m-0">
        <Card
          title="Interactivity"
          image={interactivity}
          text="Dreamby's tech will allow instant communication between members, giving real time discussions and quick decision-making.
                    The Dreamby Virtual Studio will give people the tools to work alone or with others to create content, both digital and real, with an AI driven editing suite.
                    Members can join teams working on productions in order to help, learn or provide feedback.
                    "
        />
        <Card
          title="Community"
          image={community}
          text="Dreamby is a social film platform. Joining it will put you in direct contact with people who love watching films and those that make them.
                    Want to become a pioneer to kickstart the new film industry?
                    Want to find movies that you would like to see?
                    Want to be part of a group that shares your taste in films (and introduces you to new things)?
                    Want to make your own films?
                    Want to get into the film industry?
                    Want to get rewarded for all of the above?
                    Dreamby is waiting for you.
                    Sign up here to find out more.
                    "
        />
        <Card
          title="Mission"
          image={mission}
          text="For the first time in navigate, Dreamby will be bringing fans into the filmmaking process. Dreamby will give fans access to filmmakers, creators and artists and will ensure the talent they love get paid properly for their works. We also want to  open the market to new and diverse communities, enabling creativity and opportunity to emerging and unheard talented individuals, creating an honest environment where they can flourish in a community of creators and fans. Dreamby aims to revitalise the independent film sector."
        />
        <Card
          title="AI"
          image={ai}
          text="Dreamby will enable product placement opportunities within films that will provide trackable returns via artificial intelligence. Dreamby will also supply creators with an arsenal of AI tools to write, edit, design, collaborate and more."
        />
      </div>
    </div>
  )
}

const Card = ({ title, text, image }) => {
  return (
    <div className="col-12 col-md-6">
      <div className="home_card w-100">
        <div className="text_overlay card_overlay">
          <p className="card_title">{title}</p>
        </div>
        <div className="card_overlay image_overlay">
          <p className="card_title">{title}</p>
          <p>{text}</p>
        </div>
        <LazyLoadImage
          src={image}
          effect="blur"
          height="100%"
        //   width="100%"
        />
      </div>
    </div>
  )
}
export default Cards
