import { useNavigate } from 'react-router-dom'
import image from '../../../../assets/top_image.png'
import increaseGraph from '../../../../assets/increaseIcon.png'

const TopUpload = () => {
    const navigate = useNavigate()
    return (
        <div className="custom__card top__upload__cont"
            onClick={() => navigate.push('/video-splash')}
        >
            <div className="custom__card__header">
                <div className='card__top__wrapper'>
                    <div><span>#1</span></div>
                    <p>Your Top Upload</p>
                </div>
                <select>
                    <option>Last 30 days</option>
                    <option>Last Week</option>
                    <option>Last 3 Months</option>
                    <option>Last 6 Months</option>
                    <option>Last 6 Year</option>
                </select>
            </div>
            <div className="custom__card__body">
                <div className="image__wrapper"><img src={image} alt="" /></div>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>Ranking</td>
                                <td>Top 10</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Buzz Points</td>
                                <td>100.984</td>
                                <td><img src={increaseGraph} alt="" /></td>
                            </tr>
                            <tr>
                                <td>Views</td>
                                <td><a>2.908.531</a></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TopUpload