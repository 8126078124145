import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import ContentManagmentPanel from "../components/dashboard/content_managment/content_managment";
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

function ContentManagment() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <Header />
            <ContentManagmentPanel />
        </>
    )
}

export default ContentManagment