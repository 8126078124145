import './form.css'
import Header from '../header/header'

const Form = () => {
    return (
        <div className='contact_hero'>
            <Header />
            <div className='contact_hero_section'>
                <div className="row w-100 m-0">
                    <div className="col-12">
                        <div className="contact_form">
                            <h1>Get involved and step on the red carpet!</h1>
                            <p>Feel free to reach out if you have any questions or would like to pitch your projects or tell us about yourself. We look forward to hearing from you and will endeavour to get back as fast as we can.</p>
                            <form>
                                <input type="text" placeholder="Fullname" />
                                <input type="email" placeholder="Email" />
                                <input type="text" placeholder="Phone Number" />
                                <input type="text" placeholder="What’s your favorite movie?" />
                                {/* <select>
                                    <option >Film/TV Artist</option>
                                    <option >Producer</option>
                                    <option >PR / Press</option>
                                    <option >VC / Investor</option>
                                    <option >Other</option>
                                </select> */}
                                <input type="text" placeholder="Subject" />
                                <textarea placeholder="Your Message" cols="30" rows="10"></textarea>
                                <div className="text-end w-100 mb-3"><button className="home_btn">Subscribe</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form