import "./profile.css"
import Background from "../../../assets/background.jpg"
import User from "../../../assets/profile.svg"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import m1 from "../../../assets/faces/15.jpg"
import m2 from "../../../assets/profile/m2.png"
import m3 from "../../../assets/profile/m3.png"

import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import bImg1 from "../../../assets/profile/bImg1.png"
import bImg2 from "../../../assets/profile/bImg2.png"
import bImg3 from "../../../assets/profile/bImg3.png"
import bImg4 from "../../../assets/profile/bImg4.png"
import bImg5 from "../../../assets/profile/bImg5.png"
import bImg6 from "../../../assets/profile/bImg6.png"
import bImg7 from "../../../assets/profile/bImg7.png"
import bImg8 from "../../../assets/profile/bImg8.png"
import bImg9 from "../../../assets/profile/bImg9.png"
import bImg10 from "../../../assets/profile/bImg10.png"
import bImg11 from "../../../assets/profile/bImg11.png"
import bImg12 from "../../../assets/profile/bImg12.png"

import aImg1 from "../../../assets/profile/aImg1.png"
import aImg2 from "../../../assets/profile/aImg2.png"
import aImg3 from "../../../assets/profile/aImg3.png"
import aImg4 from "../../../assets/profile/aImg4.png"
import aImg5 from "../../../assets/profile/aImg5.png"
import aImg6 from "../../../assets/profile/aImg6.png"
import aImg7 from "../../../assets/profile/aImg7.png"
import aImg8 from "../../../assets/profile/aImg8.png"
import aImg9 from "../../../assets/profile/aImg9.png"
import aImg10 from "../../../assets/profile/aImg10.png"
import aImg11 from "../../../assets/profile/aImg11.png"

import ac1 from "../../../assets/profile/ac1.png"
import ac2 from "../../../assets/profile/ac2.png"
import ac3 from "../../../assets/profile/ac3.png"
import ac4 from "../../../assets/profile/ac4.png"

import v1 from "../../../assets/profile/v1.png"
import v2 from "../../../assets/profile/v2.png"
import v4 from "../../../assets/profile/v4.png"
import v3 from "../../../assets/profile/v3.png"

import ev1 from "../../../assets/profile/ev1.png"
import ev2 from "../../../assets/profile/ev2.png"
import ev3 from "../../../assets/profile/ev3.png"
import ev4 from "../../../assets/profile/ev4.png"

import Badge from "react-bootstrap/Badge"

function ProfleSection() {
  const [modalShow, setModalShow] = useState(false)
  const [modalShowE, setModalShowE] = useState(false)
  const [modalShowBAA, setModalShowBAA] = React.useState(false)

  return (
    <>
      <div className="profileWrapper">
        <div className="pbackgroundImgWrapper">
          <img
            className="pbackgroundImg"
            src={Background}
            alt=""
          />
        </div>

        <Container fluid>
          <Row>
            <Col
              lg={12}
              md={12}
              xs={12}
            >
              <div className="profileInfo">
                <p className="text-center">
                  <img
                    src={User}
                    alt=""
                    className="profileImg"
                  />
                </p>
                <h5 className="profileInfoHead profileTransformUp  fw-bold text-center">
                  Samuel Falmouth{" "}
                  <i
                    onClick={() => setModalShow(true)}
                    className="bi bi-pen blue cursorStyle"
                  ></i>
                </h5>
                <h6 className="profileInfoSubHead profileTransformUp  fw-bolder text-center">
                  Film Maker at Newsfleet
                </h6>
                <p className="text-center prfileInfoDesc profileTransformUp ">
                  University of South Carolina <i className="bi bi-dot"></i> 251{" "}
                  <i className="bi bi-people"></i>
                </p>
                <p className="text-center prfileInfoDescSeeMore">
                  Passionate about food, film and building a sustainable future.
                  Here to create a film collaboratively and grow a community of
                  like-minded creatives..{" "}
                  <span className="blue cursorStyle"></span>
                </p>
                <p className="text-center">
                  <Badge
                    onClick={() => setModalShowBAA(true)}
                    style={{ cursor: "pointer" }}
                    pill
                    bg="info"
                  >
                    Badges & Achievements
                  </Badge>
                </p>
                <br />
              </div>

              <div className="profileViewer">
                <Row>
                  <Col
                    lg={6}
                    md={6}
                    xs={6}
                  >
                    <div className="profileViewerItem1">
                      <span className="profileViewerFigure blue fw-bold">
                        71
                      </span>
                      <span className="profileViewerDesc">
                        viewed your profile
                      </span>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    xs={6}
                  >
                    <div className="profileViewerItem2">
                      <span className="profileViewerFigure blue fw-bold">
                        21
                      </span>
                      <span className="profileViewerDesc">
                        View's of your posts
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="profileViewerNew">
                <h6 className="profileActivityHead fw-bold">
                  Friends{" "}
                  <span className="probilebadgeHeadSpan text-muted">3</span>
                </h6>
                <div className="profileBadgeFriends">
                  <Row className="mb-2">
                    <Col
                      lg={1}
                      md={4}
                      xs={4}
                    >
                      <img
                        src={m1}
                        alt=""
                        className="profileBadgeImg"
                      />
                      <i className="bi bi-dot onlineDot cGreen"></i>
                    </Col>
                    <Col
                      lg={11}
                      md={8}
                      xs={8}
                    >
                      <h6 className="profileFriendName fw-bold">
                        Jon <br />
                        <span className="profileFriendNameSpan fw-normal"></span>
                      </h6>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col
                      lg={1}
                      md={4}
                      xs={4}
                    >
                      <img
                        src={m2}
                        alt=""
                        className="profileBadgeImg"
                      />
                      <i className="bi bi-dot onlineDot cRed"></i>
                    </Col>
                    <Col
                      lg={11}
                      md={8}
                      xs={8}
                    >
                      <h6 className="profileFriendName fw-bold">
                        Helen <br />
                        <span className="profileFriendNameSpan fw-normal"></span>
                      </h6>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col
                      lg={1}
                      md={4}
                      xs={4}
                    >
                      <img
                        src={m3}
                        alt=""
                        className="profileBadgeImg"
                      />
                      <i className="bi bi-dot onlineDot cRed"></i>
                    </Col>
                    <Col
                      lg={11}
                      md={8}
                      xs={8}
                    >
                      <h6 className="profileFriendName fw-bold">
                        Janet <br />
                        <span className="profileFriendNameSpan fw-normal"></span>
                      </h6>
                    </Col>
                  </Row>
                </div>
              </div>

              <Col
                lg={4}
                md={12}
                xs={12}
              ></Col>

              <div className="profileActivity">
                <Row>
                  <h5 className="profileActivityHead fw-bold">Your Activity</h5>
                  <p className="profileActivityDesc text-muted">
                    259 followers
                  </p>
                  <Col
                    lg={6}
                    md={12}
                    xs={12}
                  >
                    <div className="profileActivity1">
                      <p className="text-center">
                        <img
                          src={ac1}
                          alt=""
                          className="profileActivitymainImg"
                        />
                      </p>
                      <h6 className="profileActivitySubHead">
                        I’m happy to share that I’m starting a new position as
                        Film Maker at AGHT Roger Falmouth, April 12 2022
                      </h6>
                      <p className="text-muted profileFriendNameSpan">
                        Jess Williams on Dreambird <br />
                        January 5, 2017
                      </p>
                      <p className="blue cursorStyle"></p>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    xs={12}
                  >
                    <div className="mt-2">
                      <Row>
                        <Col
                          lg={5}
                          md={6}
                          xs={6}
                        >
                          <img
                            src={ac2}
                            alt=""
                            className="profileActivitySubImg"
                          />
                        </Col>
                        <Col
                          lg={7}
                          md={6}
                          xs={6}
                        >
                          <h6 className="activitySubPostHead fw-bold">
                            What an honour it is to be nominated for ‘BEST
                            DESIGNER 2022’ at this years NBT Awards 🏆
                          </h6>
                          <p className="activitySubPostDesc text-muted">
                            Jess liked
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-2">
                      <Row>
                        <Col
                          lg={5}
                          md={6}
                          xs={6}
                        >
                          <img
                            src={ac3}
                            alt=""
                            className="profileActivitySubImg"
                          />
                        </Col>
                        <Col
                          lg={7}
                          md={6}
                          xs={6}
                        >
                          <h6 className="activitySubPostHead fw-bold">
                            Hey fellow UX/UI designers! Are you always on the
                            lookout for ways to level up your design game?
                          </h6>
                          <p className="activitySubPostDesc text-muted">
                            Jess liked
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-2">
                      <Row>
                        <Col
                          lg={5}
                          md={6}
                          xs={6}
                        >
                          <img
                            src={ac4}
                            alt=""
                            className="profileActivitySubImg"
                          />
                        </Col>
                        <Col
                          lg={7}
                          md={6}
                          xs={6}
                        >
                          <h6 className="activitySubPostHead fw-bold">
                            A massive THANK YOU to everyone that has emailed,
                            re-shared or personally messaged me to update my
                            creative black book so far.
                          </h6>
                          <p className="activitySubPostDesc text-muted">
                            Jess liked
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <p className="blue cursorStyle mt-4"></p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col
              lg={12}
              md={12}
              xs={12}
            >
              <div className="profileVideos">
                <h5 className="profileActivityHead fw-bold">Videos</h5>
                <p className="profileActivityDesc text-muted">Your Videos</p>
                <Row>
                  <Col
                    lg={3}
                    md={4}
                    xs={12}
                  >
                    <p className="text-center">
                      <img
                        src={v1}
                        alt=""
                        className="profileYourVideos"
                      />
                    </p>
                  </Col>
                  <Col
                    lg={3}
                    md={4}
                    xs={12}
                  >
                    <p className="text-center">
                      <img
                        src={v2}
                        alt=""
                        className="profileYourVideos"
                      />
                    </p>
                  </Col>
                  <Col
                    lg={3}
                    md={4}
                    xs={12}
                  >
                    <p className="text-center">
                      <img
                        src={v3}
                        alt=""
                        className="profileYourVideos"
                      />
                    </p>
                  </Col>
                  <Col
                    lg={3}
                    md={4}
                    xs={12}
                  >
                    <p className="text-center">
                      <img
                        src={v4}
                        alt=""
                        className="profileYourVideos"
                      />
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col
              lg={12}
              md={12}
              xs={12}
            >
              <div className="profileEvents">
                <Row>
                  <Col
                    lg={10}
                    md={9}
                    xs={4}
                  >
                    <h5 className="profileActivityHead fw-bold">Events</h5>
                    <p className="profileActivityDesc text-muted">
                      Your Events
                    </p>
                  </Col>
                  <Col
                    lg={2}
                    md={3}
                    xs={8}
                  >
                    <p className="float-end">
                      <Button
                        className="addEventBtn"
                        onClick={() => setModalShowE(true)}
                      >
                        <i className="bi bi-plus"></i> Add
                      </Button>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Row>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <p className="text-center">
                          <img
                            src={ev1}
                            alt=""
                            className="profileYourVideos"
                          />
                        </p>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <h6 className="text-muted eventDate">March 5, 2023</h6>
                        <h6 className="fw-bold">Community Meetup</h6>
                        <h6 className="eventDate">
                          Calling all local creators. Meetup in person for a fun
                          time of socialising, networking and collaboration
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Row>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <p className="text-center">
                          <img
                            src={ev2}
                            alt=""
                            className="profileYourVideos"
                          />
                        </p>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <h6 className="text-muted eventDate">March 16, 2023</h6>
                        {/*<h6 className="fw-bold">FIFA World Cup Qatar 2021</h6>*/}
                        <h6 className="eventDate">
                          Google Meetups call for our community - both local and
                          across the world. Perfect chance to get to know
                          people, and share your ideas in breakout rooms.
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Row>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <p className="text-center">
                          <img
                            src={ev3}
                            alt=""
                            className="profileYourVideos"
                          />
                        </p>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <h6 className="text-muted eventDate">March 28, 2023</h6>
                        {/*<h6 className="fw-bold">FIFA World Cup Qatar 2021</h6>*/}
                        <h6 className="eventDate">
                          Our monthly webinar will give the core team a chance
                          to update you on our progress, show you how you can
                          get involved and shout out all our highest achieving
                          fans and collaborators.
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Row>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <p className="text-center">
                          <img
                            src={ev4}
                            alt=""
                            className="profileYourVideos"
                          />
                        </p>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        xs={12}
                      >
                        <h6 className="text-muted eventDate">April 5, 2023</h6>
                        {/*<h6 className="fw-bold">FIFA World Cup Qatar 2021</h6>*/}
                        <h6 className="eventDate">
                          It’s time to cast your vote for arcs, storylines and
                          plot twists you want to see the film go in!
                        </h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col
              lg={12}
              md={12}
              xs={12}
            >
              <div className="profileVoteIn">
                <h4 className="fw-bold bHead">Project Vote-in</h4>
                <p className="jDesc">
                  This is the complete project timeline of tasks that have been
                  completed.
                </p>

                <h4 className="fw-bold bHead checkCircle">
                  <i className="bi bi-check-circle-fill"></i> Completed (21.3
                  hours)
                </h4>
                <p className="jDesc">
                  These are all jobs that have been completed
                </p>

                <div className="tTasks">
                  <Container>
                    <Row>
                      <Col
                        lg={3}
                        md={3}
                        xs={3}
                      >
                        <h6 className="tTHead fw-bold">
                          Video Project Scoping
                        </h6>
                        <p className="tTDesc">Music Video Pre-Production</p>
                      </Col>
                      <Col
                        lg={3}
                        md={3}
                        xs={3}
                      >
                        <p className="text-center tTUserSpan">
                          <img
                            src={User}
                            alt=""
                            className="tTUImg"
                          />
                          <span className="tTUserSpan fw-bold">Samuel</span>
                        </p>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        xs={2}
                      >
                        <h4 className="tTCheck text-center">
                          <i className="bi bi-check-circle-fill checkCircle"></i>
                        </h4>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        xs={2}
                      >
                        <h6 className="tTHead fw-bold text-center">1.0</h6>
                        <p className="tTDesc text-center">Hours</p>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        xs={2}
                      >
                        <h6 className="tTHead fw-bold text-center">$ 125</h6>
                        <p className="tTDesc text-center">USD</p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <br />
      <br />
      <br />
      <br />

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <AddEvent
        show={modalShowE}
        onHide={() => setModalShowE(false)}
      />
      <BadgesAndAchievements
        show={modalShowBAA}
        onHide={() => setModalShowBAA(false)}
      />
    </>
  )
}

function BadgesAndAchievements(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Badges & Achievements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>Badges</h1>
        <Container fluid>
          <Row>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg1}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg2}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg3}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg4}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg5}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg6}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg7}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg8}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg9}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg10}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg11}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={bImg12}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
          </Row>
        </Container>
        <br />
        <h1>Achievements</h1>
        <Container fluid>
          <Row>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg1}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg2}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg3}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg4}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg5}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg6}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg7}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg8}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg9}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg10}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
            <Col
              lg={3}
              md={3}
              xs={3}
            >
              <p className="text-center">
                <img
                  src={aImg11}
                  alt=""
                  className="bImg"
                />
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

function MyVerticallyCenteredModal(props) {
  let [selectedFile, setSelectedFile] = useState(null)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
            <Col
              xl={8}
              md={8}
              xs={12}
            >
              <div className="image-upload w-100 d-flex justify-content-center">
                <label htmlFor="file-input">
                  {selectedFile == null ? (
                    <p className="text-center">
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid gray",
                          borderRadius: "100%",
                        }}
                        src={
                          "https://dreambird.vercel.app/static/media/quick_upload.fe77131ef077fdf663a90aa58d4e8ff8.svg"
                        }
                      />
                    </p>
                  ) : (
                    <p className={"text-center"}>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid gray",
                          borderRadius: "100%",
                        }}
                        src={selectedFile}
                      />
                    </p>
                  )}
                </label>
                <input
                  id="file-input"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(URL.createObjectURL(e.target.files[0]))
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </Col>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
          </Row>
          <Row>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="First Name"
                aria-label="First Name"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="Last Name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="Email"
                aria-label="First Name"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="Phone Nmber"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="Country"
                aria-label="First Name"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="Zip Code"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={6}
              md={6}
              xs={12}
            >
              <Form.Control
                placeholder="Address"
                aria-label="First Name"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
            <Col
              xl={6}
              md={6}
              xs={12}
            ></Col>
          </Row>
          <Row>
            <Col
              xl={12}
              md={12}
              xs={12}
            >
              <Form.Control
                as="textarea"
                placeholder="About Yourself..."
                style={{ height: "100px" }}
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

function AddEvent(props) {
  let [selectedFile, setSelectedFile] = useState(null)
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
            <Col
              xl={8}
              md={8}
              xs={12}
            >
              <div className="image-upload w-100 d-flex justify-content-center">
                <label htmlFor="file-input">
                  {selectedFile == null ? (
                    <p className="text-center">
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid gray",
                          borderRadius: "100%",
                        }}
                        src={
                          "https://dreambird.vercel.app/static/media/quick_upload.fe77131ef077fdf663a90aa58d4e8ff8.svg"
                        }
                      />
                    </p>
                  ) : (
                    <p className={"text-center"}>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid gray",
                          borderRadius: "100%",
                        }}
                        src={selectedFile}
                      />
                    </p>
                  )}
                </label>

                <input
                  id="file-input"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(URL.createObjectURL(e.target.files[0]))
                  }}
                  style={{ display: "none" }}
                />
              </div>
            </Col>
            <Col
              xl={2}
              md={2}
              xs={12}
            ></Col>
          </Row>
          <Row>
            <Col
              xl={12}
              md={12}
              xs={12}
            >
              <Form.Control
                placeholder="Event Title"
                aria-label="First Name"
                aria-describedby="basic-addon1"
                className={"mt-3"}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              md={12}
              xs={12}
            >
              <Form.Control
                as="textarea"
                placeholder="Event Description..."
                style={{ height: "100px" }}
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProfleSection
