import { useNavigate } from 'react-router-dom'
import walletIcon from '../../../../assets/walletIcon.svg'

const Wallet = () => {
  const navigate = useNavigate()
  return (
    <div className="custom__card wallet__cont">
      <div className="custom__card__header">
        <div className="card__top__wrapper">
          <img
            src={walletIcon}
            alt=""
          />
          <p>KPI Wallet</p>
        </div>
        <select>
          <option>$</option>
          <option>€</option>
          <option>£</option>
        </select>
      </div>
      <div
        onClick={() => navigate.push("/wallet")}
        className="level__text"
      >
        <p>100000.00</p>
        <span>+75</span>
      </div>
      <div className="wallet__select">
        <select>
          <option>Last 30 days</option>
          <option>Last Week</option>
          <option>Last 3 Months</option>
          <option>Last 6 Months</option>
          <option>Last 6 Year</option>
        </select>
      </div>
    </div>
  )
}

export default Wallet