import 'swiper/css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-multi-carousel/lib/styles.css";
import 'swiper/css/navigation';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from "./pages/login";
import ForgotPassword from "./pages/forgot";
import Register from "./pages/register";
import ResetPassword from "./pages/reset";
import VideoSplash from "./pages/videosplash";
import DashboardPanel from './pages/dashboard';
import Wallet from "./pages/wallet";
import ContentManagment from "./pages/content_managment";
import Recruitment from "./pages/recruitment";
import ContentManagmentFiles from "./pages/content_managment_files";
import Marketplace from './pages/marketplace';
import Mission from "./pages/mission";
import Community from './pages/community';
import Forum from './pages/forum';
import Chat from './pages/chat';
import Friends from './pages/friends';
import Analytics from './pages/analytics';
import Jobs from "./pages/jobs";
import Profile from "./pages/profile";
import Home from './pages/home';
import Contact from './pages/contact';
import ReactGA from "react-ga4"

const TRACKING_ID = "G-PDFHZ0QJJW"
ReactGA.initialize(TRACKING_ID)
ReactGA.send({ hitType: "pageview", page: window.location.pathname })

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={'/'} element={<Home />}></Route>
        <Route path={'/contact'} element={<Contact />}></Route>
        <Route path={'/dashboard'} element={<DashboardPanel />}></Route>
        <Route path={'/login'} element={<Login />}></Route>
        <Route path={'/register'} element={<Register />}></Route>
        <Route path={'/forget-password'} element={<ForgotPassword />}></Route>
        <Route path={'/reset-password'} element={<ResetPassword />}></Route>
        <Route path={'/video-splash'} element={<VideoSplash />}></Route>
        <Route path={'/wallet'} element={<Wallet />}></Route>
        <Route path={'/content-management'} element={<ContentManagment />}></Route>
        <Route path={'/content-management-files'} element={<ContentManagmentFiles />}></Route>
        <Route path={'/recruitment'} element={<Recruitment />}></Route>
        <Route path={'/marketplace'} element={<Marketplace />}></Route>
        <Route path={'/mission'} element={<Mission />}></Route>
        <Route path={'/community'} element={<Community />}></Route>
        <Route path={'/community-forum'} element={<Forum />}></Route>
        <Route path={'/chat'} element={<Chat />}></Route>
        <Route path={'/friends'} element={<Friends />}></Route>
        <Route path={'/analytics'} element={<Analytics />}></Route>
        <Route path={'/jobs'} element={<Jobs />}></Route>
        <Route path={'/profile'} element={<Profile />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
