import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import lineDown from '../../../../assets/chart_down.svg';

const COLORS1 = ["#C51453", "#FFB238"];
const COLORS2 = ["#921D72", "#0C73BC", "#F89E3C", "#BBAF30"];
const showAxis = window.innerWidth > 991 ? true : false;

const Ads = () => {
    const filmData = [
        { clicks: '10', ad: 'ad1' },
        { clicks: '16', ad: 'ad2' },
        { clicks: '10', ad: 'ad3' },
        { clicks: '12', ad: 'ad4' },
        { clicks: '13', ad: 'ad5' },
        { clicks: '11', ad: 'ad6' },
        { clicks: '10', ad: 'ad7' },
        { clicks: '21', ad: 'ad8' },
        { clicks: '11', ad: 'ad9' },
        { clicks: '2', ad: 'ad10' }
    ];

    const data_1 = [
        { name: "Cost", value: 120 },
        { name: "Revenue", value: 80 }
    ];

    const data_2 = [
        { name: "Food", value: 200 },
        { name: "Lifestyle", value: 20 },
        { name: "Sports", value: 50 },
        { name: "Others", value: 80 }
    ];


    return (
        <div className="graph__card">
            <div className="graph custom__card_header d-flex align-items-center">
                <h6>Ad Views</h6>
                <div>
                    <span>Duration</span>
                    <select>
                        <option value="1">Last 30 days</option>
                        <option value="2">Weekly</option>
                        <option value="3">Monthly</option>
                        <option value="4">Yearly</option>
                    </select>
                </div>
            </div>
            <div className='graph__cont'>
                <div className="custom__card graph__1">
                    <div className="custom__card_header d-flex align-items-center justify-content-between">
                        <span>Overall Ad Viewing</span>
                        <span>- 125 <img src={lineDown} alt="" /></span>
                    </div>
                    <div className="marketplace__slider__cont" style={{ height: 'calc(100% - 20px)' }}>
                        <ResponsiveContainer width='100%' height="100%">
                            <LineChart data={filmData}>
                                {showAxis && <>
                                    <XAxis dataKey="ad" />
                                </>}
                                <Line type="monotone" dataKey="clicks" stroke="#C51453" />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="custom__card graph__2">
                    <div className="marketplace__slider__cont" style={{ height: '100%' }}>
                        <ResponsiveContainer width='100%' height="100%">
                            <PieChart>
                                <Pie data={data_1} dataKey="value" nameKey="name" cx="50%" cy="50%" labelLine={false} outerRadius={100}>
                                    {data_1.map((entry, index) => (
                                        <Cell key={`cell - ${index}`} fill={COLORS1[index % COLORS1.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="custom__card graph__3">
                    <div className="custom__card_header">Ad Type</div>
                    <div className="marketplace__slider__cont" style={{ height: 'calc(100% - 20px)' }}>
                        <ResponsiveContainer width='100%' height="100%">
                            <PieChart>
                                <Pie data={data_2} dataKey="value" nameKey="name" cx="50%" cy="50%" labelLine={false} outerRadius={100}>
                                    {data_2.map((entry, index) => (
                                        <Cell key={`cell - ${index}`} fill={COLORS2[index % COLORS2.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend align='right' layout='vertical' verticalAlign='middle' />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Ads