import { Link } from 'react-router-dom'
import './messages.css'

const Messages = () => {
    return (
        <div className='dashboard__messages__wrapper'>
            <div className="custom__card">
                <div className="custom__card__header">
                    <div className='d-flex align-items-center'>
                        <p>Messages</p>
                        <p className='unread__messages'>3</p>
                    </div>
                    <Link to={"/chat"}>View All Messages</Link>
                </div>
                <div className="messages__body">
                    <div className="message__row">
                        <div className="col-3 col-sm-2">rollingfilms</div>
                        <div className="col-5 col-sm-8">We are still looking for an 1st A.D. if you are still...</div>
                        <div className="col-2 col-sm-1">
                            <p className="message__tag">JOB</p>
                        </div>
                        <div className="col-2 col-sm-1">
                            <p className="message__unread">unread</p>
                        </div>
                    </div>
                    <div className="message__row">
                        <div className="col-3 col-sm-2">dreambird</div>
                        <div className="col-5 col-sm-8">Congrats! You have the Top upload of the week...</div>
                        <div className="col-2 col-sm-1">
                            <p className="message__tag">JOB</p>
                        </div>
                        <div className="col-2 col-sm-1">
                            <p className="message__unread">unread</p>
                        </div>
                    </div>
                    <div className="message__row">
                        <div className="col-3 col-sm-2">dreambird</div>
                        <div className="col-5 col-sm-8">Congrats! You have the Achieved a badge</div>
                        <div className="col-2 col-sm-1">
                            {/* <p className="message__tag">JOB</p> */}
                        </div>
                        <div className="col-2 col-sm-1">
                            <p className="message__unread">unread</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages