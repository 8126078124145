export const dashboardIcon = (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5575 11.9636C30.0835 12.4115 30.1512 13.198 29.7033 13.724C29.2554 14.2501 28.4689 14.3178 27.9429 13.8699L26.6668 12.7865V22.5001C26.6668 24.8022 24.8022 26.6668 22.5001 26.6668H7.50004C5.19899 26.6668 3.33335 24.8022 3.33335 22.5001V12.7865L2.05886 13.8699C1.5323 14.3178 0.743752 14.2501 0.296875 13.724C-0.149743 13.198 -0.0851069 12.4115 0.441303 11.9636L14.1928 0.296877C14.6563 -0.0989589 15.3438 -0.0989589 15.8074 0.296877L29.5575 11.9636ZM7.50004 24.1668H10.0001V16.2501C10.0001 15.099 10.9324 14.1668 12.0834 14.1668H17.9168C19.0678 14.1668 20.0001 15.099 20.0001 16.2501V24.1668H22.5001C23.422 24.1668 24.1668 23.422 24.1668 22.5001V10.6667L15.0001 2.88908L5.83337 10.6667V22.5001C5.83337 23.422 6.57816 24.1668 7.50004 24.1668ZM12.5001 24.1668H17.5001V16.6668H12.5001V24.1668Z"
      fill="#C51453"
    />
  </svg>
)

export const uploadIcon = (
  <svg
    width="29"
    height="23"
    viewBox="0 0 29 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5625 3.6875H18.7217L16.3223 1.47803C15.7334 0.891602 14.9424 0.5625 14.1123 0.5625H8.375C6.65137 0.5625 5.25 1.96191 5.25 3.6875V14.625C5.25 16.3506 6.64893 17.75 8.375 17.75H25.5625C27.2886 17.75 28.6875 16.3506 28.6875 14.625V6.8125C28.6875 5.08887 27.2861 3.6875 25.5625 3.6875ZM26.3438 14.625C26.3438 15.0559 25.9933 15.4062 25.5625 15.4062H8.375C7.94424 15.4062 7.59375 15.0559 7.59375 14.625V3.6875C7.59375 3.25664 7.94424 2.90625 8.375 2.90625H14.1123C14.3209 2.90625 14.5172 2.9875 14.6646 3.13516L17.75 6.03125H25.5625C25.9933 6.03125 26.3438 6.38164 26.3438 6.8125V14.625ZM22.8281 22.4375H6.42188C3.19092 22.4375 0.5625 19.8105 0.5625 16.5781V4.85938C0.5625 4.21484 1.0874 3.6875 1.73438 3.6875C2.38135 3.6875 2.90625 4.21484 2.90625 4.85938V16.5781C2.90625 18.5166 4.4834 20.0938 6.42188 20.0938H22.8281C23.4751 20.0938 24 20.6187 24 21.2656C24 21.9126 23.4775 22.4375 22.8281 22.4375Z"
      fill="#C51453"
    />
  </svg>
)

export const communityIcon = (
  <svg
    width="32"
    height="25"
    viewBox="0 0 32 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1562 0C15.7666 0 20.3125 3.84766 20.3125 8.59375C20.3125 13.3398 15.7666 17.1875 10.1562 17.1875C9.24316 17.1875 8.35938 17.0752 7.51465 16.8848C6.02051 17.8125 3.86377 18.75 1.21826 18.75C0.730957 18.75 0.289551 18.4619 0.0985352 18.0127C-0.0925781 17.5635 -0.00036133 17.0459 0.329053 16.6943C0.354492 16.6748 1.43457 15.498 2.23291 13.96C0.838867 12.4902 0 10.625 0 8.59375C0 3.84766 4.54736 0 10.1562 0ZM8.03711 14.5557C8.75 14.7607 9.46289 14.8438 10.1562 14.8438C14.4629 14.8438 17.9688 12.041 17.9688 8.59375C17.9688 5.14648 14.4629 2.34375 10.1562 2.34375C5.84961 2.34375 2.34375 5.14648 2.34375 8.59375C2.34375 10.3125 3.2085 11.582 3.93408 12.3486L5.08301 13.5645L4.31201 15.0439C4.1377 15.3369 3.94189 15.7178 3.73779 16.04C4.60254 15.791 5.4541 15.4053 6.28418 14.8486L7.09961 14.3848L8.03711 14.5557ZM21.5625 6.25977C26.9531 6.46484 31.25 10.2295 31.25 14.8438C31.25 16.875 30.4102 18.7402 29.0186 20.21C29.8145 21.748 30.8936 22.9248 30.9229 22.9443C31.25 23.2959 31.3428 23.8135 31.1084 24.2627C30.9619 24.7119 30.5176 25 30.0293 25C27.3877 25 25.2295 24.0625 23.7354 23.1348C22.8906 23.3252 22.0068 23.4375 21.0938 23.4375C17.0898 23.4375 13.6279 21.4746 11.9727 18.6279C12.8174 18.5156 13.6279 18.3252 14.3994 18.0615C15.7666 19.8779 18.2568 21.0938 21.0938 21.0938C21.7871 21.0938 22.5 21.0107 23.2129 20.8057L24.1504 20.6348L24.9658 21.0986C25.7959 21.6553 26.6455 22.041 27.5146 22.29C27.3096 21.9678 27.1143 21.5869 26.9385 21.2939L26.167 19.8145L27.3145 18.5986C28.042 17.8369 28.9062 16.5625 28.9062 14.8438C28.9062 11.6064 25.8154 8.94043 21.8311 8.62305L21.875 8.59375C21.875 7.78809 21.7676 7.00684 21.5625 6.25977Z"
      fill="#C51453"
    />
  </svg>
)

export const marketplaceIcon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1406 6.25482C24.6729 6.54779 25 7.10443 25 7.7099C25 8.63275 24.2578 9.37494 23.335 9.37494H1.66309C0.744629 9.37494 0 8.63275 0 7.7099C0 7.10443 0.329346 6.54779 0.859863 6.25482L11.9336 0.145742C12.2852 -0.0486621 12.7148 -0.0486621 13.0664 0.145742L24.1406 6.25482ZM10.9375 6.24994C10.9375 5.38568 11.6357 4.68744 12.5 4.68744C13.3643 4.68744 14.0625 5.38568 14.0625 6.24994C14.0625 6.53314 13.9844 6.8017 13.8525 7.03119H20.6934L12.5 2.5102L4.30566 7.03119H11.1475C11.0156 6.8017 10.9375 6.53314 10.9375 6.24994ZM3.125 10.9374H5.46875V18.7499H8.59375V10.9374H10.9375V18.7499H14.0625V10.9374H16.4062V18.7499H19.5312V10.9374H21.875V18.7499H22.2656C22.915 18.7499 23.4375 19.2724 23.4375 19.9218C23.4375 20.5712 22.915 21.0937 22.2656 21.0937H3.125C2.47803 21.0937 1.95312 20.5712 1.95312 19.9218C1.95312 19.2724 2.47803 18.7499 3.125 18.7499V10.9374ZM23.8281 22.6562C24.4775 22.6562 25 23.1787 25 23.8281C25 24.4775 24.4775 24.9999 23.8281 24.9999H1.5625C0.915527 24.9999 0.390625 24.4775 0.390625 23.8281C0.390625 23.1787 0.915527 22.6562 1.5625 22.6562H23.8281Z"
      fill="#C51453"
    />
  </svg>
)

export const filterIcon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.75C0 1.05977 0.559766 0.5 1.25 0.5H16.25C16.9414 0.5 17.5 1.05977 17.5 1.75C17.5 2.44141 16.9414 3 16.25 3H1.25C0.559766 3 0 2.44141 0 1.75ZM2.5 8C2.5 7.30859 3.05977 6.75 3.75 6.75H13.75C14.4414 6.75 15 7.30859 15 8C15 8.69141 14.4414 9.25 13.75 9.25H3.75C3.05977 9.25 2.5 8.69141 2.5 8ZM10 15.5H7.5C6.80859 15.5 6.25 14.9414 6.25 14.25C6.25 13.5586 6.80859 13 7.5 13H10C10.6914 13 11.25 13.5586 11.25 14.25C11.25 14.9414 10.6914 15.5 10 15.5Z"
      fill="#C51453"
    />
  </svg>
)

export const editIcon = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.06552 10.5733C1.1874 10.1602 1.41093 9.78224 1.71591 9.47755L10.626 0.566027C11.3584 -0.166337 12.5478 -0.166337 13.2803 0.566027L14.4346 1.7212C14.5254 1.81261 14.6045 1.91134 14.6748 2.01534C15.1553 2.74308 15.0762 3.73243 14.4346 4.37403L5.52245 13.2832C5.48437 13.3213 5.44628 13.333 5.40527 13.3945C5.12402 13.6436 4.79003 13.8018 4.42675 13.9336L0.901458 14.9707C0.654779 15.044 0.387884 14.9766 0.20595 14.7686C0.024011 14.6133 -0.0440105 14.3467 0.0285871 14.0977L1.06552 10.5733ZM1.7414 13.2598L4.03124 12.5859C4.09863 12.5654 4.16601 12.5127 4.23046 12.5068L3.16699 12.293C2.93554 12.2461 2.75302 12.0645 2.70673 11.833L2.49404 10.7695C2.46152 10.834 2.43486 10.9014 2.41464 10.9688L1.7414 13.2598ZM5.29394 11.5225L11.2207 5.59571L9.37792 3.75294L3.47753 9.70607L3.75292 11.2207L5.29394 11.5225Z"
      fill="#C51453"
    />
  </svg>
)

export const sortIcon = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.57812C0 1.18994 0.314941 0.875 0.703125 0.875H12.4219C12.8115 0.875 13.125 1.18994 13.125 1.57812C13.125 1.96777 12.8115 2.28125 12.4219 2.28125H0.703125C0.314941 2.28125 0 1.96777 0 1.57812ZM0 6.26562C0 5.87598 0.314941 5.5625 0.703125 5.5625H8.67188C9.06152 5.5625 9.375 5.87598 9.375 6.26562C9.375 6.65527 9.06152 6.96875 8.67188 6.96875H0.703125C0.314941 6.96875 0 6.65527 0 6.26562ZM4.92188 11.6562H0.703125C0.314941 11.6562 0 11.3428 0 10.9531C0 10.5635 0.314941 10.25 0.703125 10.25H4.92188C5.31152 10.25 5.625 10.5635 5.625 10.9531C5.625 11.3428 5.31152 11.6562 4.92188 11.6562Z"
      fill="#C51453"
    />
  </svg>
)
