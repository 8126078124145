import { useEffect } from "react";
import ResetPasswordSection from "../components/dashboard/reset_section/reset";

function ResetPassword() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <ResetPasswordSection/>
        </>
    )
}

export default ResetPassword