import search from "../../../../assets/search.svg"

const SearchFriends = ({ users }) => {
  return (
    <div className="friends__child1">
      <div className="top__community w-100">
        <div className="custom__card">
          <div className="marketplace__slider__cont">
            <div className="dashboard__filters mb-3">
              <div className="input__filter w-100">
                <img
                  src={search}
                  alt=""
                />
                <input
                  type="text"
                  className="w-100"
                  placeholder="Search people, friends..."
                />
              </div>
            </div>
            <div className="top__community__card__body">
              {users
                .map((item, index) => (
                  <div className="top__community__item_cont">
                    <div className="top__community__item">
                      <div className="community__side__card">
                        <img
                          loading="lazy"
                          src={item.image}
                          alt=""
                        />
                        <div className="community__side__card__content">
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </div>
                    <button className="loginBtn">ADD</button>
                  </div>
                ))
                .slice(0, 3)}
            </div>
          </div>
        </div>
      </div>
      <div className="top__community w-100 mt-4">
        <div className="custom__card">
          <div className="custom__card__header">
            <h6>Friend Requests</h6>
          </div>
          <div className="marketplace__slider__cont">
            <div className="top__community__card__body">
              {users
                .map((item, index) => (
                  <div
                    className="top__community__item_cont"
                    key={index}
                  >
                    <div className="top__community__item">
                      <div className="community__side__card">
                        <img
                          src={item.image}
                          alt=""
                        />
                        <div className="community__side__card__content">
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button className="loginBtn">Confirm</button>
                      <div style={{ width: "7px" }}></div>
                      <button
                        className="loginBtn"
                        style={{ background: "#6c757d" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ))
                .slice(2, 5)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchFriends
