import './footer.css'
import logo from '../../../assets/logo2.svg'

const Footer = () => {
    return (
        <div className="home_footer">
            <div className="home_footer_top p_tb">
                {/* <h3>Contact</h3> */}
                <div className="home_logo">
                    <img src={logo} alt="" />
                    <span>DREAMBY</span>
                </div>
                <div className="social_links">
                    <span><i className="fa-brands fa-discord"></i></span>
                    <span><i className="fa-brands fa-instagram"></i></span>
                    <span><i className="fa-brands fa-twitter"></i></span>
                    <span><i className="fa-brands fa-linkedin"></i></span>
                </div>
                <div className="footer_phone_email">
                    {/* <p><strong>Phone:</strong> +44 203 6336 032</p>
                    <div></div> */}
                    <p><strong>Email:</strong> hello@dreambird.co</p>
                </div>
                <div className="footer_inputs">
                    <input type="text" placeholder='Full Name' />
                    <input type="text" placeholder='Your Email' />
                </div>
                <button className='home_btn'>Subscribe</button>
            </div>
            <div className="home_footer_bottom">
                <span>@Dreamby Digital Ltg - {new Date().getFullYear()}</span>
            </div>
        </div>
    )
}

export default Footer