import "./dashboard.css"
import { Link } from "react-router-dom"
import Analytics from "../analytics/analytics"
import Community from "../community/community"
import Content from "../content/content"
import Filters from "../filters/filters"
import Friends from "../friends/friends"
import LeaderBoard from "../leaderboard/leaderboard"
import Level from "../level/level"
import Messages from "../messages/messages"
import Mission from "../mission/mission"
import Projects from "../projects/projects"
import Tips from "../tips/tips"

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="dashboard__cont">
        <div className="dashboard__child1">
          <Filters />
          <Analytics />
          <div className="dashboard__child1_3">
            <Community />
            <div>
              <Content />
              <Projects />
              <div
                className="d-flex flex-wrap"
                style={{ gap: 14 }}
              >
                <div className="col-12 col-lg-8">
                  <Messages />
                </div>
                <div className="col-12 col-lg-3 tips">
                  <Tips />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard__child2">
          <Level />
          <Mission />
          <LeaderBoard />
          <Friends />
          <div className="custom__card">
            <div className="custom__card__header mb-0">
              <p>What’s New?</p>
              <div className="add__friend">4</div>
            </div>
          </div>
          <div className="text-end">
            <Link to="/contact">Need Help?</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
