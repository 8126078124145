import "./wallet.css"
import { useState } from "react"

function Wallet() {
  let [wallet, setWallet] = useState("w1")

  return (
    <>
      <div className="wdWrapper">
        <div className="wdParent1">
          <div className="wdChild11">
            <p className="text-center">
              <i
                className="fa fa-cog walletHeaderIcons"
                aria-hidden="true"
              ></i>
            </p>
          </div>
          <div className="wdChild12">
            <p className="text-center">
              <select
                className="walletHeaderSelect"
                onChange={(e) => setWallet(e.target.value)}
              >
                <option
                  className="walletHeaderSelectOption"
                  value="w1"
                >
                  Wallet 1
                </option>
                <option
                  className="walletHeaderSelectOption"
                  value="w2"
                >
                  Wallet 2
                </option>
                <option
                  className="walletHeaderSelectOption"
                  value="w3"
                >
                  Wallet 3
                </option>
              </select>
            </p>
          </div>
          <div className="wdChild13">
            <p className="text-center">
              <i
                className="fa fa-qrcode walletHeaderIcons"
                aria-hidden="true"
              ></i>
            </p>
          </div>
        </div>

        <div className="cryptoInfoWrapper d-flex">
          <h4 className="ciText1">DREAMBIRD WALLET</h4>
        </div>

        <div className="walletAmountWrapper">
          <h1 className="text-center wa">
            <span className="wcurrency">$ </span>
            <span className="wamt">
              {wallet === "w1"
                ? "100000.00"
                : wallet === "w2"
                ? "3,0112.01"
                : wallet === "w3"
                ? "2,0112.01"
                : ""}
            </span>
            <span className="wunit"> USD</span>
          </h1>
        </div>

        <div className="transferIconWrapper d-flex justify-content-center">
          <div className="tiIcon1">
            <p className="text-center">
              <i className="fa-solid fa-share tiShareIcons1"></i>
            </p>
            <p className="text-center tiInfo1">Send</p>
          </div>
          <div className="tiIcon2">
            <p className="text-center">
              <i className="fa-solid fa-reply tiShareIcons2"></i>
            </p>
            <p className="text-center tiInfo2">Receive</p>
          </div>
        </div>
      </div>

      {/*<div className="cryptoAssetsWrapper">*/}
      {/*    <div className="caItemParent">*/}
      {/*        <div className="caiChild11">*/}
      {/*            <p className="text-center caiImgWrapper"><img src={crypto1} alt="" className="caiImg"/></p>*/}
      {/*        </div>*/}
      {/*        <div className="caiChild12">*/}
      {/*            <h4 className="caiName">Bitcoin</h4>*/}
      {/*        </div>*/}
      {/*        <div className="caiChild13">*/}
      {/*            <span className="caiImgDesc1">1 BTC</span><br/>*/}
      {/*            <span className="caiImgDesc2 customTextMuted">$ 43555</span>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="caItemParent">*/}
      {/*        <div className="caiChild11">*/}
      {/*            <p className="text-center caiImgWrapper"><img src={crypto2} alt="" className="caiImg"/></p>*/}
      {/*        </div>*/}
      {/*        <div className="caiChild12">*/}
      {/*            <h4 className="caiName">DAI</h4>*/}
      {/*        </div>*/}
      {/*        <div className="caiChild13">*/}
      {/*            <span className="caiImgDesc1">500 DAI</span><br/>*/}
      {/*            <span className="caiImgDesc2 customTextMuted">$ 500</span>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*    <div className="caItemParent">*/}
      {/*        <div className="caiChild11">*/}
      {/*            <p className="text-center caiImgWrapper"><img src={crypto3} alt="" className="caiImg"/></p>*/}
      {/*        </div>*/}
      {/*        <div className="caiChild12">*/}
      {/*            <h4 className="caiName">Bitcoin</h4>*/}
      {/*        </div>*/}
      {/*        <div className="caiChild13">*/}
      {/*            <span className="caiImgDesc1"><i className="fa-solid fa-angle-right caiIcon"></i></span><br/>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </>
  )
}

export default Wallet
