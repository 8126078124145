import './level.css'
import level from '../../../../assets/level.svg'
import XP from '../../../../assets/xp.svg'

const Level = () => {
    return (
        <div className="dashboard__user__level">
            <div className="custom__card user__level__wrapper">
                <div className="icon__wrapper mb-2">
                    <img src={level} alt="" />
                </div>
                <p>Level: Shooting Star</p>
                <div className="xp__wrapper">
                    <img src={XP} alt="" />
                    <span>XP  100.984</span>
                </div>
                <p className='level__pro'>PRO</p>
            </div>
        </div>
    )
}

export default Level