import "./community.css"
import Carousel from "react-multi-carousel"
import cate1 from "../../../assets/cate1.png"
import cate2 from "../../../assets/cate2.png"
import cate3 from "../../../assets/cate3.png"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1025 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

function VideoSplashCommunity() {
  return (
    <>
      <div className="homeFooterWrapper">
        <h5 className="footerHead fw-bold">Community</h5>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
              <h6 className="vscSubHead1Highlighted fw-bold mt-4">Shares</h6>
              <h6 className="vscSubHead2 fw-bold mt-4 ">Reviews</h6>
            </div>
            <hr className="seperator" />
            <Carousel responsive={responsive}>
              <div className="vscCateItem">
                <p className="vscCateItemHead">Dreambird</p>
                <p>
                  <img
                    className="vscCateItemImg"
                    src={cate1}
                  />
                </p>
                <p className="vscCateItemDesc">
                  @dreambird Countdown to Watch Party...
                </p>
              </div>
              <div className="vscCateItem">
                <p className="vscCateItemHead">Instagram</p>
                <p>
                  <img
                    className="vscCateItemImg"
                    src={cate2}
                  />
                </p>
                <p className="vscCateItemDesc">
                  @cassieglow Galentine with my bestie...{" "}
                </p>
              </div>
              <div className="vscCateItem">
                <p className="vscCateItemHead">TikTok</p>
                <p>
                  <img
                    className="vscCateItemImg"
                    src={cate3}
                  />
                </p>
                <p className="vscCateItemDesc">@lyfe Battle of the Vals...</p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoSplashCommunity
