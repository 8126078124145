import { useEffect } from "react";
import LoginSection from "../components/dashboard/login_section/login";

function Login() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <LoginSection/>
        </>
    )
}

export default Login