import { useEffect } from 'react'
import Header from '../components/dashboard/header/header'
import ChatPopUp from '../components/dashboard/chatpop/chatpop'
import Graphs from '../components/dashboard/graphs/graphs'

const Analytics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <div className="dashboard__panel">
                <Header />
                <div className='dashboard analytics'>
                    <div className="dashboard__cont">
                        <div className="w-100">
                            <Graphs />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Analytics