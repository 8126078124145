import React, { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import ProfleSection from "../components/dashboard/profile/profile";

function Profile() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <Header/>
            <ProfleSection/>
        </>
    )
}

export default Profile