import { useEffect } from "react";
import CommunitySection from "../components/dashboard/community/community"
import Header from "../components/dashboard/header/header"
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

const Community = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <div className="dashboard__panel">
                <Header />
                <CommunitySection />
            </div>
        </>
    )
}

export default Community