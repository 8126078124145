import { useEffect } from "react";
import Header from "../components/dashboard/header/header";
import RecruitmentPanel from "../components/dashboard/recruitment/recruitment";
import ChatPopUp from "../components/dashboard/chatpop/chatpop";

function Recruitment() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <ChatPopUp />
            <Header />
            <RecruitmentPanel />
        </>
    )
}

export default Recruitment