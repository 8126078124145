import './mission.css'
import { Link } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import mission from '../../../../assets/mission.svg'

const Mission = () => {
    return (
        <div className='mission__wrapper'>
            <div className="custom__card">
                <div className="custom__card__header">
                    Mission Progress
                </div>
                <div className="mission__body">
                    <div className="icon__wrapper"><img src={mission} alt="" /></div>
                    <div className="d-flex justify-content-between flex-column w-100" style={{ marginLeft: '8px' }}>
                        <div className="mission__title">
                            <p>Laughter Track</p>
                            <span>Watch 7 Comedies</span>
                        </div>
                        <div className="mission__progress">
                            <ProgressBar
                                completed={5}
                                maxCompleted={7}
                                bgColor={"#FFB238"}
                                height={6}
                                isLabelVisible={false}
                                animateOnRender={true}
                            />
                            <span>5/7</span>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '12px' }} className="my-2">Next Mission: Add New Upload</div>
                <div className="text-end"><Link to='/mission'>View All Missions</Link></div>
            </div>
        </div>
    )
}

export default Mission