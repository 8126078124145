import { useEffect, useState } from "react"
import Dashboard from "../components/dashboard/dashboard_panel/dashboard/dashboard"
import Header from "../components/dashboard/header/header"
import ChatPopUp from "../components/dashboard/chatpop/chatpop"
import Modal from "../components/website/modal/modal"

const DashboardPanel = () => {
  const [modalShow, setModalShow] = useState(
    sessionStorage.getItem("entryModal", 0) ? false : true,
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <ChatPopUp />
      <div className="dashboard__panel">
        <Header />
        <Dashboard />
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(true)}
      />
    </>
  )
}

export default DashboardPanel
