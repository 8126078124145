import './mission_section.css'
import {Container,Row,Col} from "react-bootstrap";


import b1 from '../../../assets/mission_badges/b1.jpg'
import b2 from '../../../assets/mission_badges/b2.jpg'
import b3 from '../../../assets/mission_badges/b3.jpg'
import b4 from '../../../assets/mission_badges/b4.jpg'
import b5 from '../../../assets/mission_badges/b5.jpg'
import b6 from '../../../assets/mission_badges/b6.jpg'
import b7 from '../../../assets/mission_badges/b7.jpg'
import b8 from '../../../assets/mission_badges/b8.jpg'
import b9 from '../../../assets/mission_badges/b9.jpg'
import b10 from '../../../assets/mission_badges/b10.jpg'
import b11 from '../../../assets/mission_badges/b11.jpg'
import b12 from '../../../assets/mission_badges/b12.jpg'
import b13 from '../../../assets/mission_badges/b13.jpg'
import b14 from '../../../assets/mission_badges/b14.jpg'
import b15 from '../../../assets/mission_badges/b15.jpg'
import b16 from '../../../assets/mission_badges/b16.png'
import b17 from '../../../assets/mission_badges/b17.png'
import b18 from '../../../assets/mission_badges/b18.png'
import b19 from '../../../assets/mission_badges/b19.png'
import b20 from '../../../assets/mission_badges/b20.png'
import b21 from '../../../assets/mission_badges/b21.png'

function MissionSection() {
    return(
        <>
            <div className="missionWrapper">
                <div className="mtParent">
                    <div className="mtChild11">
                        <h4 className="mtHead fw-bold">Journey</h4>
                    </div>
                    <div className="mtChild12">
                        <h4 className="mtHead fw-bold"><i className="fa-solid fa-lock"></i> 3/18</h4>
                    </div>
                </div>

                <br/>

                <Container fluid>
                    <Row>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChild">
                                <p className="text-center"><img src={b1} alt="" className="mCImg"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">Watched 10 films</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b2} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve invited 5 friends who participated on a project</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b3} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve invested in a project</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b4} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve joined a community</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b5} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve added 10 friends</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b6} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve uploaded your film</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b7} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve watched 30 ads</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b8} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve created a job post </h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b9} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve started a guild </h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b10} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve filled in your profile </h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b11} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve made 5 posts</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b12} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You have contributed on 10 films</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b13} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve edited a film using Dreambird editing suite</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b14} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve joined a creator team</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b15} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve earned your first £1000</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b16} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve successfully completed 5 jobs</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b17} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You received 5 contributions on your film </h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b18} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You’ve invited 5 friends who participated on a project</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b19} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You have shared your collection </h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b20} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">Your post has received over 100,000 views</h6>
                        </Col>
                        <Col lg={3} md={4} xs={4}>

                            <div className="mCChildLocked">
                                <p className="text-center"><img src={b21} alt="" className="mCImgLocked"/></p>
                            </div>
                            <br/>
                            <h6 className="missionBadgeName text-center">You have successfully funded your project</h6>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}
export default MissionSection