import { useState } from "react"

const ChatCenter = ({
  user,
  chatHandle,
  chatShow,
  setChatShow,
  chatProfileShow,
  setChatProfileShow,
}) => {
  const date = new Date()
  const time = `${date.getHours()}:${date.getMinutes()}`
  const [message, setMessage] = useState("")

  const messagesHandle = (e) => {
    e.preventDefault()
    if (message.trim().length > 0) {
      chatHandle(user.id, {
        type: "right",
        message: message.trim(),
        time,
      })
      setMessage("")
    }
  }
  return (
    <div
      className={`chat__child2 ${chatProfileShow.chatCenter} ${chatShow.chatCenter}`}
    >
      <div className="custom__card h-100">
        <ChatHeader
          user={user}
          setChatShow={setChatShow}
          setChatProfileShow={setChatProfileShow}
        />
        <ChatBody user={user} />
        <ChatFooter
          message={message}
          setMessage={setMessage}
          messagesHandle={messagesHandle}
        />
      </div>
    </div>
  )
}

const ChatHeader = ({ user, setChatProfileShow, setChatShow }) => {
  return (
    <div className="chat__header">
      <div className="d-flex align-items-center">
        <div
          onClick={() =>
            setChatShow((prev) => ({
              chatLeft: "chat__child1__display",
              chatCenter: "",
              ChatRight: "",
            }))
          }
          className="chat__back__icon icon__wrapper"
        >
          <i className="fa fa-arrow-left"></i>
        </div>
        <div className="chat__header__img">
          <img
            src={user?.image}
            alt=""
          />
        </div>
        <div className="chat__header__info">
          <div>{user?.name}</div>
          <p>Active {user?.lastSeen}</p>
        </div>
      </div>
      <div
        onClick={() => {
          setChatProfileShow((prev) => ({ chatCenter: "", chatRight: "" }))
          setChatShow((prev) => ({
            chatLeft: "",
            chatCenter: "",
            ChatRight: "chat__child3__display_",
          }))
        }}
        className="icon__wrapper"
      >
        <i className="fa fa-ellipsis"></i>
      </div>
    </div>
  )
}

const ChatBody = ({ user }) => {
  return (
    <div
      className="chat__body"
      id="chat__body"
    >
      <div className="chat__body__content">
        {user?.messages.map((message) => (
          <div className={`chat__body__${message.type} chat__content__child`}>
            <p>{message.message}</p>
            <span>{message.time}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

const ChatFooter = ({ message, setMessage, messagesHandle }) => {
  return (
    <div className="chat__footer">
      <form
        className="d-flex align-items-center"
        onSubmit={messagesHandle}
      >
        <input
          type="text"
          placeholder="Write a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <div className="chat__emoji">
          <i className="fa fa-smile"></i>
        </div>
        <div>
          <label htmlFor="chat__file">
            <i className="fa fa-paperclip"></i>
          </label>
          <input
            type="file"
            id="chat__file"
          />
        </div>
        <button type="submit">
          <i className="fa-brands fa-telegram"></i>
        </button>
      </form>
    </div>
  )
}

export default ChatCenter
