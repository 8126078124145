import logo from "../../../assets/logo.png"
import Form from "react-bootstrap/Form"

function ResetPasswordSection() {
  return (
    <>
      <div className="loginWrapper d-flex align-items-center justify-content-center">
        <div className="loginDiv">
          <h1 className="logo text-center">
            <img
              className="loginLogo"
              src={logo}
            />
          </h1>
          <Form className="mt-5">
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="fw-bolder">Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Code"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="fw-bolder">Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter New Password"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label className="fw-bolder">Retype Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Retype New Password"
              />
            </Form.Group>
            <br />
            <p className="text-center">
              <button className="loginBtn">Reset Password</button>
            </p>
          </Form>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordSection
