import { useEffect } from "react"
import Cards from "../components/website/cards/cards"
import Dreambird from "../components/website/dreambird/dreambird"
import Footer from "../components/website/footer/footer"
import Hero from "../components/website/hero/hero"
import Intro from "../components/website/intro/intro"

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="background_image"></div>
      <div className="homepage">
        <Hero />
        <Intro />
        <Dreambird />
        <Cards />
        <Footer />
      </div>
    </>
  )
}

export default Home
