import './dreambird.css'
import DREAMBY from '../../../assets//website/DREAMBY.svg'

const Dreambird = () => {
    return (
        <div className='home_dreambird p_tb'>
            <div className="hero_title">The Future Of Film</div>
            <div className="hero_title">is</div>
            <img loading='lazy' decoding='async' src={DREAMBY} alt="" />
        </div>
    )
}

export default Dreambird